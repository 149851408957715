import languages from 'app/config/languages';
import { get, map } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { RootState } from 'store';
import { setRegion } from 'store/Region';

const LanguageDropdown = () => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const { region } = useSelector((rootState: RootState) => rootState.region);
  const location = useLocation();
  const history = useHistory();

  const changeLanguageAction = lang => {
    const params = new URLSearchParams(location.search);
    if (params) {
      let tempArr: string[] = [];
      params.forEach((value, key) => {
        tempArr.push(key);
      });
      tempArr.forEach(item => {
        params.delete(item);
      });
    }
    history.push({ search: params.toString() });
    dispatch(setRegion(lang));
    window.location.reload();
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={get(languages, `${region}.flag`)}
            alt="lang"
            height="16"
            className="me-1"
            style={{ border: '1px solid #dfdfdf' }}
          />
          <span className="align-middle">
            {get(languages, `${region}.label`)}
          </span>
        </DropdownToggle>
        <DropdownMenu className="language-switch" end>
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${region === key ? 'active' : 'none'}`}
            >
              <img
                src={languages[key].flag}
                alt="flag"
                className="me-1"
                height="12"
                style={{ border: '1px solid #dfdfdf' }}
              />
              <span className="align-middle">{languages[key].label}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default LanguageDropdown;
