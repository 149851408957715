import * as Sentry from '@sentry/react';
import { OptionItems } from 'app/models';
import {
  HomeTypeDataWithSellingName,
  ReformatHomeTypeDataWithSellingName,
} from 'app/models/Booking';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export interface UseCustomPropertyParams<P> {
  getDataListCall: () => Promise<P[]>;
}

export const UseHomeTypeComplete = <P extends HomeTypeDataWithSellingName>({
  getDataListCall,
}: UseCustomPropertyParams<P>) => {
  const [getResData, setGetResData] = useState<P[]>([]);
  const [formatedOptionItemList, setFormatedOptionItemList] = useState<
    OptionItems[]
  >([]);
  const [formatedSellingNameList, setFormatedSellingNameList] = useState<
    OptionItems[]
  >([]);

  const [flattedHomeType, setFlattedHomeType] =
    useState<ReformatHomeTypeDataWithSellingName[]>();

  const [sellingNameMap, setSellingNameMap] = useState<Record<string, string>>(
    {},
  );
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getListData = useCallback(async () => {
    setIsFetching(true);

    try {
      const res = await getDataListCall();
      setGetResData(res);
      let newArr: ReformatHomeTypeDataWithSellingName[] = [];
      res.forEach(item => {
        const { typeName, Properties } = item;
        Properties.forEach(property => {
          newArr.push({ ...property, typeName });
        });
      });
      setFlattedHomeType(newArr);

      // Formate to label value
      const formatedData = newArr.map(item => {
        const optionItem = {
          label: `${item.name}`,
          value: item.propertyId,
        };
        return optionItem;
      });

      setFormatedOptionItemList(formatedData);

      let tempOptionalItemArr: OptionItems[] = [];
      const formatedNewOptionData = newArr.map(item => {
        const { Rooms, propertyId } = item;

        const tempRoomsArr = Rooms.map(room => {
          const optionItem = {
            label: `${propertyId} | ${room.selling_name}`,
            value: `${room.selling_name}|${propertyId}`,
          };
          return optionItem;
        });
        return [...tempOptionalItemArr, ...tempRoomsArr];
      });
      setFormatedSellingNameList(formatedNewOptionData.flatMap(item => item));

      const tempSellNameMap: Record<string, string> = {};
      res.forEach(subbrand => {
        const { Properties } = subbrand;
        Properties.forEach(property => {
          const { Rooms, propertyId } = property;
          Rooms.forEach(room => {
            tempSellNameMap[`${room.selling_name}`] = propertyId;
          });
        });
      });
      setSellingNameMap(tempSellNameMap);
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      toast.warning('Please check your network.');
    } finally {
      setIsFetching(false);
    }
  }, [getDataListCall]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  return {
    getResData,
    formatedOptionItemList,
    flattedHomeType,
    isFetching,
    formatedSellingNameList,
    sellingNameMap,
  };
};
