import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    /* height: 100%;
    width: 100%; */
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    /* min-height: 100%;
    min-width: 100%; */
  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  .table-action-btn {
    background-color: transparent;
    border: none;
    padding: 0px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .user-select-none {
    user-select: none;
  }

  .link-disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
  }
  .table-buttons {
    background-color: transparent;
    border: unset;
    padding: 0px;
  }
  .clear-btn {
    background-color: transparent;
    border: unset;
    padding: 0px;
  }
`;
