import { IMAGE_SIZE } from 'app/config';
import { formatBytes } from 'app/helpers/FormHelper';
import { FieldHookConfig, useField } from 'formik';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Label, Row } from 'reactstrap';

import './form.scss';

interface OtherProps {
  label?: string;
  multiple?: boolean;
  accept?: string | string[];
}

const PDFField = ({
  label,
  multiple,
  accept,
  ...props
}: OtherProps & FieldHookConfig<any[] | null>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);
  const onDelete = (original, index) => {
    const temp = [...original];
    temp.splice(index, 1);
    if (temp.length > 0) {
      helper.setValue(temp);
    } else {
      helper.setValue(null);
    }
  };

  const onDrop = files => {
    let filesArr: File[] = [];
    if (field.value) {
      filesArr = [...field.value];
    }
    files.forEach(file => {
      if (file.size > IMAGE_SIZE) {
        toast.warning('PDF cannot be larger than 10MB');
        return;
      }
      file['formattedSize'] = formatBytes(file.size);
      if (multiple) {
        filesArr.push(file);
      } else {
        filesArr = [file];
      }
      helper.setValue(filesArr);
    });
  };
  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'text-danger' : ''
      }`}
    >
      {label ? <Label>{label}</Label> : null}
      <Dropzone
        accept={accept || 'image/*'}
        multiple={multiple ? true : false}
        onDrop={acceptedFiles => {
          onDrop(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick mt-2" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h4>DRAG AND DROP PDF HERE</h4>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {field.value
          ? field.value.map((file, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + '-file'}
                >
                  <div className="p-2">
                    <Row className="align-items-start">
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {file.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{file.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <Button
                          color="danger"
                          onClick={() => onDelete(field.value, i)}
                        >
                          <i className="bx bx-trash-alt" />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })
          : null}
      </div>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default PDFField;
