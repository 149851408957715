export interface ListResult<T> {
  count: number;
  hasNext: boolean;
  limit: number;
  offset: number;
  page: number;
  rows: T[];
}

export interface UserResult<T> {
  count: number;
  row: T[];
}

export interface Column {
  name: string;
  field: string;
  isImage?: boolean;
  isSecureImage?: boolean;
  isAvatar?: boolean;
  dateFormat?: string;
  buildingNumber?: boolean;
}

export interface Filter<T> {
  limit?: number;
  offset?: number;
  page?: number;
  desc?: keyof T;
  asc?: keyof T;
}

export interface TimeStamp {
  createdAt: string;
  deletedAt: null | string;
  updatedAt: string;
}

export interface TimeStampInProperty {
  deleted_at: string | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface OptionItems {
  value: string;
  label: string;
  __isNew__?: boolean;
}

export interface GroupedOptionItems {
  key: OptionItems;
}

export interface BreadcrumbItemProps {
  title: string;
  path: string;
}

export interface LocalFile extends File {
  lastModified: number;
  lastModifiedDate?: Date;
  name: string;
  path?: string;
  size: number;
  delete?: boolean;
  preview?: null | undefined | any | string;
  formattedSize?: string;
}

export interface FileResponse {
  fileName: string;
  url: string;
  supportSizes: string[];
}

export interface CheckBoxOption {
  value: string;
  label: string;
  check: boolean;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly OptionItems[];
}

export interface ListColumn<Type> {
  text: string;
  dataField: keyof Type | 'img' | 'menu' | '';
  canSort?: boolean;
  formatter?: (item: Type) => JSX.Element;
}

export interface SortType {
  desc?: string;
  asc?: string;
}

export enum LocaleOptions {
  en = 'English',
  tc = '繁體中文',
  sc = '简体中文',
}

export type PublishStatus = 'Published' | 'Unpublished';

export interface DeletableTabItem<T> {
  label: string;
  value: string;
  data?: T | null;
  referToTab?: string;
  action?: (
    data: DeletableTabItem<T>,
    allData: DeletableTabItem<T>[],
  ) => void | null;
}

export type FormType = 'create' | 'edit' | 'copy' | 'new';
