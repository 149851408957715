import { FormikErrors } from 'formik';

export const renderError = <P>(errors: FormikErrors<P>, activeLang: string) => {
  const temp: string[] = [];
  Object.keys(errors).forEach((lang, index) => {
    if (lang !== activeLang) {
      temp.push(lang.toUpperCase());
    }
  });
  return temp.length
    ? `Error(s) occured in ${temp.map(lang => lang)} form. Please check.`
    : '';
};
