/**
 *
 * Asynchronously loads the component for FaqPage
 *
 */
import { lazyLoad } from 'utils/loadable';

export const FaqFormPage = lazyLoad(
  () => import('./index'),
  module => module.FaqFormPage,
);
