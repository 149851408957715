import LOGO_SQ from 'app/assets/images/logo_sq.png';
import { ImgHTMLAttributes, useState } from 'react';

interface ImageProps {
  defaultImgSrc?: string;
  imageContainerClassName?: string;
}

export const Image = ({
  src,
  className,
  defaultImgSrc,
  imageContainerClassName,
  ...props
}: ImageProps & ImgHTMLAttributes<HTMLImageElement>) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);

  return (
    <div className={imageContainerClassName} style={{ height: '100%' }}>
      {loading && (
        <div className={`placeholder-glow h-100`}>
          <span className={`placeholder col-12 h-100`}></span>
        </div>
      )}
      <img
        className={`${className} ${loading && 'd-none'}`}
        src={!isError ? src : defaultImgSrc ? defaultImgSrc : LOGO_SQ}
        draggable={false}
        alt={props.alt}
        onLoad={() => {
          setLoading(false);
        }}
        onError={() => setError(true)}
        {...props}
      />
    </div>
  );
};
