import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface RegionState {
  region: string;
}

const initialState = {
  region: 'hk',
} as RegionState;

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setRegion(state, action: PayloadAction<string>) {
      state.region = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { setRegion, reset } = regionSlice.actions;
export default regionSlice.reducer;
