import {
  BenefitOfferTranslation,
  Filter,
  ListResult,
  PartnerBenefitListItem,
  PartnerCategoryData,
  PartnerCategoryListItem,
  PartnerCategoryTranslations,
  PartnerListItem,
  PartnerTranslation,
} from '../models';
import Axios from './';

/**
* * Get partner list (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/partnership/partner)
+*/

export interface GetPartnerListParams extends Filter<PartnerListItem> {
  publishStatus?: string;
  categoryNames?: string;
  name?: string;
}

export const getPartnerListData = async (params: GetPartnerListParams) => {
  const { data } = await Axios.get<ListResult<PartnerListItem>>(
    '/partnership/partner',
    {
      params,
    },
  );
  return data;
};

/**
* * Get partner category list (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/partnership/category)
+*/

export interface GetPartnerCategoryListParams
  extends Filter<PartnerCategoryListItem> {
  name?: string;
}

export const getPartnerCategoryListData = async (
  params: GetPartnerCategoryListParams,
  Region: string,
) => {
  const { data } = await Axios.get<ListResult<PartnerCategoryListItem>>(
    '/partnership/category',
    {
      params,
      headers: {
        'X-Region': Region,
      },
    },
  );
  return data;
};

/**
* * get One Partner Category (https://weaveliving-app-dev2.appicidea.com/doc/mobile-app-internal/v2#get-/v1/partner/category/-partnerCategoryId-)
+*/

export const getOnePartnerCategory = async (
  partnerCategoryId: number | string,
) => {
  const { data } = await Axios.get<PartnerCategoryData>(
    `/partnership/category/${partnerCategoryId}`,
  );
  return data;
};

/**
* * Create an Partner Category (https://weaveliving-app-dev2.appicidea.com/doc/mobile-app-internal/v2#post-/v1/partner/category)
+*/

export interface CreateOnePartnerCategoryDataParams {
  name: string;
  region: string;
  ordering?: number;
  PartnerCategoryTranslations?: PartnerCategoryTranslations[];
  partnerCategoryId: number;
}

export const createOnePartnerCategory = async (
  params: CreateOnePartnerCategoryDataParams,
) => {
  const { data } = await Axios.post<PartnerCategoryData>(
    '/partnership/category',
    params,
  );
  return data;
};

/**
* * Update an Partner Category (https://weaveliving-app-dev2.appicidea.com/doc/mobile-app-internal/v2#patch-/v1/partner/category/-partnerCategoryId-)
+*/

export const updateOnePartnerCategory = async (
  partnerCategoryId: number | string,
  params: Partial<CreateOnePartnerCategoryDataParams>,
) => {
  const { data } = await Axios.patch<PartnerCategoryData>(
    `/partnership/category/${partnerCategoryId}`,
    params,
  );
  return data;
};

/**
* * Delete an Partner Category (https://weaveliving-app-dev2.appicidea.com/doc/mobile-app-internal/v2#delete-/v1/partner/-partnerId-)
+*/

export const deleteOnePartnerCategory = async (
  partnerCategoryId: number | string,
) => {
  const { data } = await Axios.delete<string>(
    `/partnership/category/${partnerCategoryId}`,
  );
  return data;
};

/**
* * Get one partner detail (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/partnership/partner/-partnerId-)
+*/

export const getOnePartner = async (partnerId: string | number) => {
  const { data } = await Axios.get<PartnerListItem>(
    `/partnership/partner/${partnerId}`,
  );
  return data;
};

/**
* * create one partner (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#post-/v1/partnership/partner)
+*/

export interface CreateOnePartnerParams {
  icon: string;
  name: string;
  categoryName?: string | null;
  images: string[];
  contents: string;
  publishedDate: string;
  PartnerTranslations?: PartnerTranslation[];
  partnerCategoryId?: number | null;
  // region: string;
}

export const createOnePartner = async (params: CreateOnePartnerParams) => {
  const { data } = await Axios.post<any>(`/partnership/partner`, params);
  return data;
};

/**
* * update one partner detail (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#patch-/v1/partnership/partner/-partnerId-)
+*/

export const updateOnePartner = async (
  partnerId: string | number,
  params: Partial<CreateOnePartnerParams>,
) => {
  const { data } = await Axios.patch<any>(
    `/partnership/partner/${partnerId}`,
    params,
  );
  return data;
};

/**
* * delete one partner (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#delete-/v1/partnership/partner/-partnerId-)
+*/

export const deleteOnPartner = async (partnerId: string | number) => {
  const { data } = await Axios.delete<any>(`/partnership/partner/${partnerId}`);
  return data;
};

/**
* * Get partner benefit list (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/partnership/benefitOffer)
+*/

export interface GetPartnerBenefitParams extends Filter<PartnerListItem> {
  partnerId?: string;
}

export const getPartnerBenefitsListData = async (
  params: GetPartnerBenefitParams,
) => {
  const { data } = await Axios.get<ListResult<PartnerBenefitListItem>>(
    '/partnership/benefitOffer',
    {
      params,
    },
  );
  return data;
};

/**
* * create partner benefit (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#post-/v1/partnership/benefitOffer)
+*/

export interface CreatePartnerBenefitParams {
  partnerId: number | string;
  title: string;
  startDateTime: string | null;
  endDateTime: string | null;
  BenefitOfferTranslations?: BenefitOfferTranslation[];
}

export const createOnePartnerBenefits = async (
  params: CreatePartnerBenefitParams,
) => {
  const { data } = await Axios.post<ListResult<PartnerBenefitListItem>>(
    '/partnership/benefitOffer',
    params,
  );
  return data;
};

/**
* * get one partner benefit (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/partnership/benefitOffer/-benefitOfferId-)
+*/

export const getOnePartnerBenefits = async (
  benefitOfferId: string | number,
) => {
  const { data } = await Axios.get<PartnerBenefitListItem>(
    `/partnership/benefitOffer/${benefitOfferId}`,
  );
  return data;
};

/**
* * update one partner benefit (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#patch-/v1/partnership/benefitOffer/-benefitOfferId-)
+*/

export const updateOnePartnerBenefits = async (
  benefitOfferId: string | number,
  params: Partial<CreatePartnerBenefitParams>,
) => {
  const { data } = await Axios.patch<any>(
    `/partnership/benefitOffer/${benefitOfferId}`,
    params,
  );
  return data;
};

/**
* * delete one partner benefit (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#delete-/v1/partnership/benefitOffer/-benefitOfferId-)
+*/

export const deleteOnePartnerBenefits = async (
  benefitOfferId: string | number,
) => {
  const { data } = await Axios.delete<any>(
    `/partnership/benefitOffer/${benefitOfferId}`,
  );
  return data;
};
