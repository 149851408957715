import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutAdmin } from 'store/Auth';

export function LogoutPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logoutAdmin(history));
  });

  return <></>;
}
