import * as Sentry from '@sentry/react';
import { uploadImage } from 'app/services/CommonService';

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const uploadSingleImage = async (image: any) => {
  try {
    if (image[0]?.path) {
      const formData = new FormData();
      if (image[0].size) {
        formData.append(`file`, image[0]);
        const imagesRes = await uploadImage(formData);
        return imagesRes.fileName;
      }
    } else if (image[0]?.image) {
      return image[0].image;
    }
  } catch (err) {
    Sentry.captureException(err);
    throw new Error();
  }
};

export const uploadMultipleImages = async (images: any) => {
  try {
    if (images === null || images.length === 0) {
      return [];
    } else if (images && images.length > 0) {
      const imgArr: string[] = [];
      for (let i = 0; i < images.length; i++) {
        const formData = new FormData();
        if (images[i].size) {
          formData.append(`file`, images[i]);
          const imagesRes = await uploadImage(formData);
          imgArr.push(imagesRes.fileName);
        } else {
          imgArr.push(images[i].image);
        }
      }
      return imgArr;
    } else {
      return images[0].image;
    }
  } catch (err) {
    Sentry.captureException(err);
    throw new Error();
  }
};
