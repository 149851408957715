import HKIcon from 'app/assets/images/hk.png';
import JPIcon from 'app/assets/images/jp.png';
import KRIcon from 'app/assets/images/kr.png';
import SGIcon from 'app/assets/images/sg.png';

const languages = {
  hk: {
    label: 'Hong Kong',
    flag: HKIcon,
  },
  sg: {
    label: 'Singapore',
    flag: SGIcon,
  },
  jp: {
    label: 'Japan',
    flag: JPIcon,
  },
  kr: {
    label: 'Korea',
    flag: KRIcon,
  },
};

export default languages;

export const LANG_CONFIG = ['en', 'tc', 'sc', 'jp', 'kr'];
