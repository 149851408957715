/**
 *
 * Asynchronously loads the component for AddOnProductFormPage
 *
 */
import { lazyLoad } from 'utils/loadable';

export const AddOnProductFormPage = lazyLoad(
  () => import('./index'),
  module => module.AddOnProductFormPage,
);
