import { ColumnDefinitionType, CustomFAQ } from 'app/models';
import { Property } from 'app/models/Property';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { Link } from 'react-router-dom';

export const columns: (
  homeTypeList?: Property[],
) => ColumnDefinitionType<CustomFAQ, keyof CustomFAQ>[] = homeTypeList => [
  {
    key: 'title',
    header: 'Title',
    formatter: (item: CustomFAQ) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{item.title}</div>
        </h5>
      </>
    ),
  },
  {
    key: 'propertyIds',
    header: 'Property',
    formatter: (item: CustomFAQ) => {
      const tempReduceArr = item.propertyIds?.reduce((prev, cur) => {
        if (homeTypeList) {
          const tempMatch = homeTypeList?.find(
            homeType => homeType.propertyId === cur,
          );
          if (tempMatch) {
            prev.push(tempMatch?.propertyName);
          }
        }
        return prev;
      }, [] as string[]);

      return (
        <>
          <h5 className="font-size-14 mb-1">
            <div className="text-dark">
              {tempReduceArr &&
              tempReduceArr.length > 0 &&
              item.propertyIds &&
              item.propertyIds.length > 0
                ? tempReduceArr.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))
                : '-'}
            </div>
          </h5>
        </>
      );
    },
  },
  {
    key: 'createdAt',
    header: 'Date of creation',
    formatter: (item: CustomFAQ) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(item.createdAt).format('DD-MM-YYYY')}
          </div>
        </h5>
      </>
    ),
  },
  {
    key: 'faqId',
    header: '  ',
    text: '',
    formatter: (item: CustomFAQ) => (
      <ul className="list-inline font-size-20 contact-links mb-0 d-flex flex-row justify-content-center me-4">
        <li className="list-inline-item px-2">
          <Tooltip
            placement="top"
            overlayStyle={{ padding: '5px 0 5px 0' }}
            overlay={<div className="px-3 py-1 fs-6">Edit FAQ</div>}
            overlayInnerStyle={{ borderRadius: '4px' }}
          >
            <Link
              to={`./faq/edit/${item.faqId}`}
              data-tip
              data-for={`view-faq-${item.faqId}`}
            >
              <i className="mdi mdi-clipboard-edit text-muted" />
            </Link>
          </Tooltip>
        </li>
      </ul>
    ),
  },
];
