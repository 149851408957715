/**
 *
 * FaqForm
 *
 */
import * as Sentry from '@sentry/react';
import { CaseCategoryBreadCrumb } from 'app/components/CasePage';
import Dialog from 'app/components/Dialog';
import { FaqForm } from 'app/components/FAQ/FAQForm';
import { APP_NAME } from 'app/config';
import { DEFAULT_FAQ_FORM_VALUE, FAQFormType } from 'app/constants/FAQ';
import {
  formatFAQFormData,
  transformFAQToFormData,
} from 'app/helpers/FAQHelper';
import { OptionItems } from 'app/models';
import {
  createOneFaq,
  deleteOneFAQByID,
  getAllCaseCategory,
  getAllParentCategory,
  getOneCaseCategory,
  getOneFAQByID,
  updateOneFAQByID,
} from 'app/services/CaseServices';
import { getPropertyShortList } from 'app/services/PropertyServices';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';

// form

// form schema

interface FaqFormParams {
  id: string;
  type: 'parent' | 'cat' | 'subCat';
  faqId: string;
}

export const FaqFormPage = () => {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const params = useParams<FaqFormParams>();

  const [faqId, setFaqId] = useState<number | null>(null);
  const [form, setForm] = useState<FAQFormType>(DEFAULT_FAQ_FORM_VALUE);
  const [parentCategory, setParentCategory] = useState<OptionItems[]>([]);
  const [caseCategoryMap, setCaseCategoryMap] = useState<
    Record<string, OptionItems[]>
  >({});
  const [propertyList, setPropertyList] = useState<OptionItems[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

  const getParentCategory = useCallback(async () => {
    try {
      const parentCatRes = await getAllParentCategory({
        limit: 99,
        offset: 0,
      });
      setParentCategory(
        parentCatRes.rows.map(res => ({
          label: res.name,
          value: res.caseParentCategoryId,
        })),
      );
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  }, []);

  useEffect(() => {
    getParentCategory();
  }, [getParentCategory]);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { faqId: FAQID, type, id } = params;
    try {
      const propertyRes = await getPropertyShortList({
        limit: 99,
        offset: 0,
      });
      setPropertyList(
        propertyRes.rows.map(item => ({
          label: item.propertyName.split(' – ')[1],
          value: item.propertyId,
        })),
      );
      if (FAQID) {
        const res = await getOneFAQByID(FAQID);
        const { faqId, CaseCategoryFAQs } = res;
        let tempCategoryMap: Record<string, OptionItems[]> = {};
        if (CaseCategoryFAQs && CaseCategoryFAQs.length) {
          for (let i = 0; i < CaseCategoryFAQs.length; i++) {
            if (
              CaseCategoryFAQs[i].caseParentCategoryId &&
              !tempCategoryMap[CaseCategoryFAQs[i].caseParentCategoryId!]
            ) {
              const caseCatRes = await getAllCaseCategory({
                limit: 99,
                offset: 0,
                caseParentCategoryId:
                  CaseCategoryFAQs[i].caseParentCategoryId?.toString(),
              });
              tempCategoryMap = {
                ...tempCategoryMap,
                [CaseCategoryFAQs[i].caseParentCategoryId!]:
                  caseCatRes.rows.map(res => ({
                    label: res.name,
                    value: res.caseCategoryId,
                  })),
              };
            }
          }
        }
        setFaqId(faqId);
        setForm(transformFAQToFormData(propertyRes.rows, res));
        setCaseCategoryMap(tempCategoryMap);
        dispatch(changePreloader(false));
      } else if (type && id) {
        const tempForm = transformFAQToFormData(propertyRes.rows);
        if (type === 'parent') {
          tempForm.en.caseItems.push({
            caseParentCategoryId: id,
            caseCategoryId: '',
            caseSubCategoryId: '',
          });
        } else if (type === 'cat') {
          const res = await getOneCaseCategory(id);
          tempForm.en.caseItems.push({
            caseParentCategoryId: res.caseParentCategoryId,
            caseCategoryId: id,
            caseSubCategoryId: '',
          });
          getCaseCategory(res.caseParentCategoryId);
        } else {
          tempForm.en.caseItems.push({
            caseParentCategoryId: '',
            caseCategoryId: '',
            caseSubCategoryId: '',
          });
        }

        setForm(tempForm);
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      toast.warning('Network error.  Please try again.');
    } finally {
      dispatch(changePreloader(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  // form submit
  const onSubmit = async (values: FAQFormType) => {
    setLoading(true);
    const { type, id } = params;
    try {
      const data = formatFAQFormData(values);
      if (faqId) {
        await updateOneFAQByID(faqId!, data);
        toast.success('Edit FAQ successfully.');
      } else {
        await createOneFaq(data);
        toast.success('Add FAQ successfully.');
        history.push(`/case-categories/${type}/${id}/faq`);
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      toast.warning('Fail to add FAQ, please try again.');
    } finally {
      setLoading(false);
    }
  };

  const deleteConfirm = async () => {
    setLoading(true);
    const { type, id } = params;
    try {
      await deleteOneFAQByID(faqId!);
      toast.success('Delete FAQ successfully.');
      history.push(`/case-categories/${type}/${id}/faq`);
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      toast.warning('Fail to delete FAQ, please try again');
    } finally {
      setLoading(false);
      setDeleteVisible(false);
    }
  };

  const getCaseCategory = async (caseParentCategoryId: string | null) => {
    try {
      if (caseParentCategoryId) {
        try {
          if (!caseCategoryMap[caseParentCategoryId]) {
            const caseCatRes = await getAllCaseCategory({
              limit: 99,
              offset: 0,
              caseParentCategoryId: caseParentCategoryId
                ? caseParentCategoryId
                : undefined,
            });
            setCaseCategoryMap(prev => ({
              ...prev,
              [caseParentCategoryId]: caseCatRes.rows.map(res => ({
                label: res.name,
                value: res.caseCategoryId,
              })),
            }));
          }
        } catch (err) {
          console.log(err);
          Sentry.captureException(err);
        }
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };

  const TITLE = `${`${faqId ? 'Edit' : 'Add'}`} FAQ`;

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        <Container fluid={true}>
          <CaseCategoryBreadCrumb />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>FAQ Form</CardTitle>
                  {!isPreloader ? (
                    <FaqForm
                      faqId={faqId}
                      form={form}
                      propertyList={propertyList}
                      parentCategory={parentCategory}
                      caseCategoryMap={caseCategoryMap}
                      loading={loading}
                      onSubmit={onSubmit}
                      getCaseCategory={getCaseCategory}
                      setDeleteVisible={setDeleteVisible}
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Dialog
          title={'Delete FAQ'}
          visible={deleteVisible}
          onClose={() => setDeleteVisible(false)}
          loading={loading}
          onConfirm={() => deleteConfirm()}
        >
          <p>{`Confirm to delete FAQ (id: ${faqId})?`}</p>
        </Dialog>
      </div>
    </>
  );
};
