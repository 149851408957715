import { getCurrentAdminProfile } from 'app/services/AdminServices';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const nonAuthPaths: string[] = ['/login', '/logout'];

export const useAuth = () => {
  const history = useHistory();
  const getUserProfile = useCallback(async () => {
    try {
      await getCurrentAdminProfile();
      if (nonAuthPaths.includes(window.location.pathname)) {
        history.replace('/');
      }
    } catch (err) {
      console.log(err);
      history.replace('/login');
    } finally {
    }
  }, [history]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);
};
