import {
  AddOnProductFormPage,
  AddOnProductListPage,
} from 'app/pages/AddOnProductPage';
import { AdminFormPage, AdminListPage } from 'app/pages/AdminPage';
import {
  AnnouncementCategoryFormPage,
  AnnouncementCategoryListPage,
  AnnouncementFormPage,
  AnnouncementListPage,
} from 'app/pages/AnnouncementPage';
import { LoginPage } from 'app/pages/Authentication';
import { LogoutPage } from 'app/pages/Authentication/Logout';
import {
  BannerFormPage,
  BannerListPage,
  CommunityBannerFormPage,
  CommunityBannerListPage,
  PopupBannerFormPage,
} from 'app/pages/BannerPage';
import { CaseListPage } from 'app/pages/CasePage';
import { FaqFormPage } from 'app/pages/CasePage/FaqFormPage';
import { FaqListPage } from 'app/pages/CasePage/FaqListPage';
import {
  EventCalendarPage,
  EventCategoryPage,
  EventDetailPage,
  EventFormPage,
  EventListPage,
} from 'app/pages/EventPage';
import {
  FaqFormPage as CustomFaqFormPage,
  FaqListPage as FAQPage,
} from 'app/pages/FAQPage';
import {
  GeneralInfoFormPage,
  GeneralInfoListPage,
} from 'app/pages/GeneralInfoPage';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { MaintenanceFormPage } from 'app/pages/MaintenancePage';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import {
  NotificationFormPage,
  NotificationListPage,
} from 'app/pages/NotificationPage';
import {
  PartnerCategoryFormPage,
  PartnerCategoryListPage,
  PartnerDetailPage,
  PartnerListPage,
} from 'app/pages/PartnerPage';
import { PartnerFormPage } from 'app/pages/PartnerPage/PartnerFormPage';
import { PreCheckInListPage } from 'app/pages/PreCheckInPage';
import {
  BankInfoFormPage,
  LaundryFormPage,
  PropertyDetailPage,
  PropertyListPage,
} from 'app/pages/PropertyPage';
import {
  CheckoutFormPage,
  CheckoutListPage,
  SatisfactionSurveyListPage,
  SurveyFormPage,
  SurveyListPage,
} from 'app/pages/SurveyPage';
import { UserListPage } from 'app/pages/UserPage';
import { UserFormPage } from 'app/pages/UserPage/UserFormPage';
import { Redirect } from 'react-router-dom';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
}
const userRoutes: Route[] = [
  { path: '/', component: HomePage },

  // admin
  { path: '/admins', component: AdminListPage },
  { path: '/admins/new', component: AdminFormPage, exact: true },
  { path: '/admins/edit/:adminId', component: AdminFormPage, exact: true },
  {
    path: '/admins/edit/:adminId/:password',
    component: AdminFormPage,
    exact: true,
  },
  // user
  { path: '/users', component: UserListPage },
  { path: '/users/new', component: UserFormPage },
  { path: '/users/view/:userId', component: HomePage },

  // announcement
  { path: '/announcements', component: AnnouncementListPage },
  {
    path: '/announcements-categories',
    component: AnnouncementCategoryListPage,
  },
  {
    path: '/announcements-categories/:type',
    component: AnnouncementCategoryFormPage,
  },
  {
    path: '/announcements-categories/:type/:announcementCategoryId',
    component: AnnouncementCategoryFormPage,
  },
  { path: '/announcements/:type', component: AnnouncementFormPage },
  {
    path: '/announcements/:type/:announcementId',
    component: AnnouncementFormPage,
  },

  // Event
  { path: '/events', component: EventListPage },
  { path: '/events/new', component: EventFormPage },
  { path: '/events/edit/:eventId', component: EventFormPage },
  { path: '/events/view/:eventId', component: EventDetailPage },
  // Event Category
  { path: '/event-categories', component: EventCategoryPage },

  { path: '/event-calendar', component: EventCalendarPage },

  // Banner
  { path: '/banners', component: BannerListPage },
  { path: '/banners/:type', component: BannerFormPage },
  { path: '/banners/:type/:bannerId', component: BannerFormPage },
  { path: '/community-banners', component: CommunityBannerListPage },
  { path: '/community-banners/new', component: CommunityBannerFormPage },
  {
    path: '/community-banners/edit/:bannerId',
    component: CommunityBannerFormPage,
  },
  { path: '/popup-banner', component: PopupBannerFormPage },

  // add on products
  { path: '/add-on', component: AddOnProductListPage },
  { path: '/add-on/edit/:productId', component: AddOnProductFormPage },

  // case
  { path: '/case-categories', component: CaseListPage },
  { path: '/case-categories/:type/:parentId', component: CaseListPage },
  { path: '/case-categories/:type/:id/faq', component: FaqListPage },
  { path: '/case-categories/:type/:id/faq/new', component: FaqFormPage },
  {
    path: '/case-categories/:type/:id/faq/edit/:faqId',
    component: FaqFormPage,
  },

  // General info
  { path: '/generals', component: GeneralInfoListPage },
  // { path: '/generals/:type/:parentId', component: GeneralInfoListPage },
  { path: '/generals/:type', component: GeneralInfoFormPage },
  { path: '/generals/:type/:generalInfoId', component: GeneralInfoFormPage },

  // Survey
  // { path: '/surveys', component: SurveyListPage },
  { path: '/surveys-firsttime-login', component: SurveyListPage },
  { path: '/surveys-pre-checkin', component: PreCheckInListPage },
  { path: '/surveys-satisfaction', component: SatisfactionSurveyListPage },
  { path: '/surveys-checkout', component: CheckoutListPage },
  // { path: '/surveys-pre-checkin/new', component: PreCheckInFormPage },

  { path: '/surveys/view/login/:userSurveyId', component: SurveyFormPage },
  { path: '/surveys/view/checkin/:userCheckinId', component: SurveyFormPage },
  {
    path: '/surveys/view/satisfaction/:satisfactionFormId',
    component: SurveyFormPage,
  },
  {
    path: '/surveys/view/checkout/:checkoutFormId',
    component: CheckoutFormPage,
  },

  //Chcekin
  // { path: '/pre-check-in', component: PreCheckInListPage },

  //Maint
  { path: '/maintenances', component: MaintenanceFormPage },

  // Property
  { path: '/properties', component: PropertyListPage },
  { path: '/properties/view/:propertyId', component: PropertyDetailPage },
  {
    path: '/properties/view/:propertyId/edit/laundry/:propertyId',
    component: LaundryFormPage,
  },
  {
    path: '/properties/view/:propertyId/edit/bank/:propertyId',
    component: BankInfoFormPage,
  },

  //partners
  { path: '/partners', component: PartnerListPage },
  { path: '/partners/detail/:partnerId', component: PartnerDetailPage },
  { path: '/partners/:type', component: PartnerFormPage },
  { path: '/partners/:type/:partnerId', component: PartnerFormPage },
  {
    path: '/partners-categories',
    component: PartnerCategoryListPage,
  },
  {
    path: '/partners-categories/:type',
    component: PartnerCategoryFormPage,
  },
  {
    path: '/partners-categories/:type/:partnerCategoryId',
    component: PartnerCategoryFormPage,
  },

  // Notification
  { path: '/notifications', component: NotificationListPage },
  { path: '/notifications/new', component: NotificationFormPage },

  // faq
  { path: '/faq', component: FAQPage },
  { path: '/faq/new', component: CustomFaqFormPage },
  { path: '/faq/edit/:faqId', component: CustomFaqFormPage },

  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
