import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import Switch from 'react-switch';

interface symbolProps {
  statement?: string;
}

const Offsymbol = ({ statement }: symbolProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {statement ? statement : 'No'}
    </div>
  );
};

const OnSymbol = ({ statement }: symbolProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {statement ? statement : 'Yes'}
    </div>
  );
};

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  customTrue?: string;
  customFalse?: string;
  disabled?: boolean;
  containerClass?: string;
  onChange?: (e: any) => void;
}

const SwitchField = (props: OtherProps & FieldHookConfig<boolean>) => {
  const [field, meta, helpers] = useField(props);
  const { label, horizontal, disabled, containerClass, onChange } = props;
  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'has-danger' : ''
      } ${containerClass ? containerClass : ''}`}
      style={
        horizontal
          ? { display: 'flex', flexDirection: 'row', alignItems: 'center' }
          : {}
      }
    >
      {label ? (
        <div
          style={{
            margin: '8px 8px 8px 0px',
            fontFamily: 'Georgia,Times,Times New Roman,serif',
          }}
        >
          {label}
        </div>
      ) : null}
      <div className="square-switch">
        <Switch
          uncheckedIcon={<Offsymbol statement={props.customFalse} />}
          checkedIcon={<OnSymbol statement={props.customTrue} />}
          onColor="#f8b425"
          onChange={e => {
            if (onChange) {
              onChange(e);
            } else {
              helpers.setValue(e);
            }
          }}
          disabled={disabled ? true : false}
          checked={field.value}
        />
      </div>
    </div>
  );
};

export default SwitchField;
