/**
 *
 * PartnerForm
 *
 */
import * as Sentry from '@sentry/react';
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import { LangTabSelect } from 'app/components/Common/LangTabSelect';
import Dialog from 'app/components/Dialog';
import {
  DateField,
  HTMLField,
  ImageField,
  InputField,
  SelectField,
} from 'app/components/Form';
import { APP_NAME } from 'app/config';
import { LANG_CONFIG } from 'app/config/languages';
import {
  formatPartnerFormData,
  transformPartnerToFormData,
} from 'app/helpers/PartnerHelper';
import { FormType, OptionItems } from 'app/models';
import { MultiLangForm } from 'app/models/lang';
import {
  createOnePartner,
  deleteOnPartner,
  getOnePartner,
  getPartnerCategoryListData,
  updateOnePartner,
} from 'app/services/PartnerService';
import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';
import { renderError } from 'utils/error';
import * as Yup from 'yup';

const DEFAULT_LANG = 'en';

export type PartnerFormItem = {
  icon: any;
  name: string;
  partnerCategoryId: string | null;
  images: any;
  contents: string | null;
  publishedDate: Date | Date[] | string;
  isPublishNow: string;
  partnerTranslationId?: number | null;
  categoryName?: string | null;
};

// form initialValues
export const PARTNER_FORM_ITEM = {
  icon: null,
  name: '',
  images: null,
  contents: '',
};

interface PartnerFormParams {
  partnerId: string;
  type: FormType;
}

const DEFAULT_FORM_VALUE: MultiLangForm<PartnerFormItem> = LANG_CONFIG.reduce(
  (prev, current) =>
    current === DEFAULT_LANG
      ? {
          ...prev,
          [current]: {
            ...PARTNER_FORM_ITEM,
            isPublishNow: 'true',
            publishedDate: '',
            partnerCategoryId: '',
            categoryName: '',
          },
        }
      : {
          ...prev,
          [current]: {
            ...PARTNER_FORM_ITEM,
            partnerTranslationId: null,
          },
        },
  {},
);

const SCHEMA_NECESSARY = Yup.object().shape({
  icon: Yup.mixed().required('Required'),
  name: Yup.string().min(1).max(200).required('Required'),
  partnerCategoryId: Yup.mixed().required('Required'),
  images: Yup.mixed().required('Required'),
  contents: Yup.string()
    .nullable()
    .max(10000, 'Max. 10,000 characters')
    .required('Required'),
  publishedDate: Yup.date()
    .nullable()
    .when('isPublishNow', (isPublishNow, passSchema) =>
      isPublishNow === 'true' ? passSchema : passSchema.required('Required'),
    ),
});

const SCHEMA_OPTIONAL = Yup.object().shape({
  icon: Yup.mixed(),
  name: Yup.string()
    .max(200)
    .test('optional-trans-title', 'Required', (title, context) => {
      const { icon, images, contents } = context.parent;
      if ((contents || images || icon ? true : false) && !title) {
        return false;
      } else {
        return true;
      }
    }),
  images: Yup.mixed(),
  contents: Yup.string().nullable().max(10000, 'Max. 10,000 characters'),
});

const SCHEMA_OBJ = LANG_CONFIG.reduce(
  (prev, current) =>
    current === DEFAULT_LANG
      ? { ...prev, [current]: SCHEMA_NECESSARY }
      : { ...prev, [current]: SCHEMA_OPTIONAL },
  {},
);

const SCHEMA = Yup.object(SCHEMA_OBJ);

export const PartnerFormPage = () => {
  const { layout, region } = useSelector((rootState: RootState) => rootState);
  const { isPreloader } = layout;
  const { region: Region } = region;
  const dispatch = useDispatch();
  const params = useParams<PartnerFormParams>();
  const { type } = params;

  const [partnerId, setPartnerId] = useState<number | null>(null);
  const [form, setForm] =
    useState<MultiLangForm<PartnerFormItem>>(DEFAULT_FORM_VALUE);
  const [categoryList, setCategoryList] = useState<OptionItems[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeLang, setActiveLang] = useState<string>(DEFAULT_LANG);

  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const history = useHistory();

  const getCategoryList = useCallback(async () => {
    try {
      const categotyRes = await getPartnerCategoryListData(
        {
          limit: 999,
          offset: 0,
        },
        Region,
      );
      setCategoryList(
        categotyRes.rows.map(row => ({
          label: row.name,
          value: row.partnerCategoryId.toString(),
        })),
      );
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  }, [Region]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  const getFormData = useCallback(async () => {
    dispatch(changePreloader(true));
    try {
      if (params.partnerId) {
        const getRes = await getOnePartner(params.partnerId);

        if (getRes) {
          const tempForm = await transformPartnerToFormData(getRes);
          const { partnerId } = getRes;

          setPartnerId(partnerId);

          setForm(tempForm);
        } else {
          toast.warning('Please check your network.');
        }
      }
    } catch (err: any) {
      console.log(err);
      Sentry.captureException(err);
      toast.warning('Please check your network.');
    } finally {
      dispatch(changePreloader(false));
    }
  }, [dispatch, params.partnerId]);

  useEffect(() => {
    getFormData();
  }, [getFormData]);

  const onSubmit = async (
    forms: MultiLangForm<PartnerFormItem>,
    action: FormikHelpers<MultiLangForm<PartnerFormItem>>,
  ) => {
    setLoading(true);

    try {
      const params = await formatPartnerFormData(forms, action);

      if (type === 'edit' && partnerId) {
        await updateOnePartner(partnerId, params);
        toast.success('Updated successfully');
      } else {
        await createOnePartner(params);
        toast.success('Created successfully');
        history.push('/partners');
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      toast.error(
        `Failed to ${
          type === 'edit' ? 'edit' : 'create'
        } a partnership. Please try again.`,
      );
    } finally {
      setLoading(false);
    }
  };

  const onDeleteData = useCallback(async () => {
    setLoading(true);
    try {
      if (params.partnerId) {
        await deleteOnPartner(params.partnerId);
        toast.success('Deleted Successfully.');
        setDeleteVisible(false);
        history.replace('/partners');
      } else {
        toast.warning('Please check your network.');
      }
    } catch (err: any) {
      console.log(err);
      Sentry.captureException(err);
      toast.error('Failed to delete. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [params.partnerId, history]);

  const TITLE = `${`${partnerId ? 'Edit' : 'Add new'}`}  partner`;

  const BreadcrumbsOptions = [
    { title: 'Partners', path: '/partners' },
    { title: 'Partner List', path: '/partners' },
    { title: TITLE, path: '#' },
  ];
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        <Container fluid={true}>
          <Breadcrumbs
            title="PartnerForm"
            breadcrumbItems={BreadcrumbsOptions}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={SCHEMA}
                        onSubmit={onSubmit}
                      >
                        {({ values, submitCount, errors, setFieldValue }) => (
                          <Form className="form-horizontal">
                            <Row className="mb-3">
                              <Col
                                sm={12}
                                className="d-flex justify-content-end align-items-center"
                              >
                                <LangTabSelect
                                  options={LANG_CONFIG.map(lang => ({
                                    label: lang.toLocaleUpperCase(),
                                    value: lang,
                                  }))}
                                  value={activeLang}
                                  onChange={lang => setActiveLang(lang)}
                                />
                              </Col>
                              {submitCount > 0 && (
                                <Col
                                  sm={12}
                                  className="d-flex justify-content-end align-items-center"
                                >
                                  <div className="text-danger mt-1">
                                    {renderError<
                                      MultiLangForm<PartnerFormItem>
                                    >(errors, activeLang)}
                                  </div>
                                </Col>
                              )}
                            </Row>
                            {LANG_CONFIG.map((lang, index) => (
                              <Row
                                key={index}
                                className={`mb-3 ${
                                  lang === activeLang ? 'd-block' : 'd-none'
                                }`}
                              >
                                <CardTitle>Partner Information</CardTitle>
                                <hr />
                                <Row>
                                  <Col sm={12} lg={6}>
                                    <InputField
                                      name={`${lang}.name`}
                                      label="Name"
                                      placeholder="partner name"
                                      type="text"
                                    />
                                  </Col>

                                  <Col sm={12} lg={6}>
                                    <SelectField
                                      name={`${DEFAULT_LANG}.partnerCategoryId`}
                                      label="Category"
                                      placeholder="Select category"
                                      options={categoryList}
                                      onChange={e => {
                                        setFieldValue(
                                          `${DEFAULT_LANG}.categoryName`,
                                          e.label,
                                        );
                                        setFieldValue(
                                          `${DEFAULT_LANG}.partnerCategoryId`,
                                          e.value,
                                        );
                                      }}
                                    />
                                  </Col>

                                  <Col md={6}>
                                    <SelectField
                                      name={`${DEFAULT_LANG}.isPublishNow`}
                                      label="Publish Time"
                                      options={[
                                        { label: 'Now', value: 'true' },
                                        {
                                          label: 'Schedule Time',
                                          value: 'false',
                                        },
                                      ]}
                                    />
                                  </Col>
                                  {values[DEFAULT_LANG].isPublishNow !==
                                  'true' ? (
                                    <Col md={6}>
                                      <DateField
                                        name={`${DEFAULT_LANG}.publishedDate`}
                                        label="Publish Time"
                                        placeholder="select time"
                                        enableTimeInCAL
                                      />
                                    </Col>
                                  ) : null}
                                </Row>

                                <CardTitle>Partner Images</CardTitle>
                                <hr />
                                <Row>
                                  <Col md={12}>
                                    <ImageField
                                      name={`${lang}.icon`}
                                      label="Partner Icon (recommended aspect ratio 1:1)"
                                    />
                                  </Col>
                                  <Col md={12}>
                                    <ImageField
                                      name={`${lang}.images`}
                                      label={
                                        'Cover Images (recommended aspect ratio 1:1) (allow mutiple images)'
                                      }
                                      multiple
                                    />
                                  </Col>
                                </Row>

                                <CardTitle>Partner Description</CardTitle>
                                <hr />
                                <Row>
                                  <Col md={12}>
                                    <HTMLField name={`${lang}.contents`} />
                                  </Col>
                                </Row>
                              </Row>
                            ))}

                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  'Save'
                                )}
                              </Button>
                              {partnerId ? (
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ms-1"
                                  onClick={() => setDeleteVisible(true)}
                                >
                                  Delete
                                </Button>
                              ) : null}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Dialog
          visible={deleteVisible}
          title={'Delete Partner'}
          onClose={() => {
            setDeleteVisible(false);
            setLoading(false);
          }}
          loading={loading}
          onConfirm={() => onDeleteData()}
          confirmBtnText="Confirm"
        >
          <p>{`Confirm to delete Partner?`}</p>
        </Dialog>
      </div>
    </>
  );
};
