import { TimeStamp } from './Common';
import { Translation } from './lang';

export type GeneralInfoType =
  | 'Facility'
  | 'User Guide'
  | 'House Rules'
  | 'Others';

export interface GeneralInfoTranslation extends Translation {
  generalInfoTranslationId?: number | null;
  icon?: string | null;
  name?: string;
  content?: string;
  subTitle: string | null;
  images?: string[];
}

export interface GeneralInfoData extends TimeStamp {
  generalInfoId: number | string;
  propertyId: string;
  generalInfoCategoryId: number | null;
  icon: string;
  name: string;
  subTitle: string | null;
  content: string;
  order: number;
  images: string[];
  GeneralInfoTranslations: GeneralInfoTranslation[];
  // type: GeneralInfoType;
}

export interface GeneralInfoCategoryData extends TimeStamp {
  // generalInfoCategoryId: number;
  parentCategoryId: number | null;
  propertyId: string;
  name: string;
  icon: string;
  placeholderTitle: string;
  order: number;
  subCategoryCount: number;
  generalInfoCount: number;
}

export interface CustomGeneralData extends GeneralInfoData {
  id: string;
}

export enum GeneralInfoOptions {
  'parent' = 'Parent Category',
  'cat' = 'Case Category',
  'subCat' = 'Sub Category',
}

export type GeneralInfoCategoryType = keyof typeof GeneralInfoOptions;
