/**
 *
 * UserForm
 *
 */
import * as Sentry from '@sentry/react';
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import {
  DateField,
  InputField,
  SelectField,
  SwitchField,
} from 'app/components/Form';
import { APP_NAME } from 'app/config';
import { CreateUserParams, createUser } from 'app/services/UserService';
import { Form, Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { RootState } from 'store';
import * as Yup from 'yup';

// form

// form schema
const Schema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Required'),
  password: Yup.string().min(8, 'At lease 8 characters').required('Required'),
  firstName: Yup.string().required('Required'),
});

// form type
type FormItem = {
  email: string;
  isReceivedInformation: boolean;
  firstName: string;
  lastName: string;
  prefix: string;
  mobile: string;
  gender: string;
  dateOfBirth: string;
  currentLivingLocation: string;
  password: string;
};

// form initialValues
const FORM_ITEM = {
  email: '',
  isReceivedInformation: true,
  firstName: '',
  lastName: '',
  prefix: '',
  mobile: '',
  gender: 'Male',
  dateOfBirth: '',
  currentLivingLocation: '',
  password: '',
};

export const UserFormPage = () => {
  const history = useHistory();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );

  const [form] = useState<FormItem>(FORM_ITEM);
  const [loading, setLoading] = useState<boolean>(false);

  // form submit
  const onSubmit = async (
    values: FormItem,
    actions: FormikHelpers<FormItem>,
  ) => {
    setLoading(true);
    try {
      const data: CreateUserParams = {
        ...values,
        dateOfBirth: values.dateOfBirth?.[0]
          ? moment(values.dateOfBirth[0]).format('YYYY-MM-DD')
          : '',
      };
      await createUser(data);
      toast.success('Add new user successfully.');
      history.push('/users');
    } catch (err: any) {
      if (err?.response && err?.response?.status === 409) {
        actions.setErrors({ email: 'email has been signed up.' });
      } else {
        Sentry.captureException(err);
      }
      toast.warning('Fail to add new user, please try again.');
    } finally {
      setLoading(false);
    }
  };

  const TITLE = 'Add New User';

  const BreadcrumbsOptions = [
    { title: 'User', path: '/users' },
    { title: 'User List', path: '/users' },
    { title: TITLE, path: '#' },
  ];
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        <Container fluid={true}>
          <Breadcrumbs title="UserForm" breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>User Information</CardTitle>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                      >
                        {({
                          values,
                          handleBlur,
                          handleChange,
                          touched,
                          errors,
                          setFieldValue,
                        }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col lg={6}>
                                <InputField
                                  name="firstName"
                                  label="First Name"
                                  placeholder="First Name"
                                  type="text"
                                />
                              </Col>
                              <Col lg={6}>
                                <InputField
                                  name="lastName"
                                  label="Last Name"
                                  placeholder="Last Name"
                                  type="text"
                                />
                              </Col>

                              <Col lg={6}>
                                <InputField
                                  name="email"
                                  label="Email Address"
                                  placeholder="Email Address"
                                  type="text"
                                />
                              </Col>

                              <Col lg={2}>
                                <InputField
                                  name="prefix"
                                  label="Prefix"
                                  placeholder="Prefix"
                                  type="text"
                                />
                              </Col>

                              <Col lg={4}>
                                <InputField
                                  name="mobile"
                                  label="Phone Number"
                                  placeholder="Phone Number"
                                  type="text"
                                />
                              </Col>

                              <Col lg={4}>
                                <SelectField
                                  name="gender"
                                  label="Gender"
                                  placeholder="Gender"
                                  options={[
                                    { label: 'Male', value: 'Male' },
                                    { label: 'Female', value: 'Female' },
                                  ]}
                                />
                              </Col>
                              <Col lg={4}>
                                <DateField
                                  name="dateOfBirth"
                                  label="Date Of Birth"
                                  placeholder="Date Of Birth"
                                />
                              </Col>
                              <Col lg={4}>
                                <InputField
                                  name="currentLivingLocation"
                                  label="Current Living Location"
                                  placeholder="Current Living Location"
                                />
                              </Col>
                              <Col lg={6}>
                                <InputField
                                  name="password"
                                  label="Password"
                                  placeholder="password"
                                  type="password"
                                />
                              </Col>
                              <Col lg={6}>
                                <SwitchField
                                  name="isReceivedInformation"
                                  label="Received Information"
                                />
                              </Col>
                            </Row>

                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  'Save'
                                )}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
