import { Filter, TimeStamp } from './Common';
import { FAQ } from './FAQ';

export interface CategoryBase extends TimeStamp {
  name: string;
  subTitle: string;
  placeholderTitle: string | null;
  icon: string;
  order: number;
  modifiedOn: string;
  propertyIds?: string[];
}

export interface ParentCategory extends CategoryBase {
  caseParentCategoryId: string;
  CaseCategories: CaseCategory[];
}

export interface CaseCategory extends CategoryBase {
  caseCategoryId: string;
  caseParentCategoryId: string;
  CaseSubCategories: CaseSubCategory[];
}

export interface CaseSubCategory extends CategoryBase {
  caseSubCategoryId: string;
  caseCategoryId: string;
}

export interface UpdateOneCategoryParams {
  name?: string;
  subTitle?: string | null;
  propertyIds?: string[];
  placeholderTitle?: string | null;
  icon?: string | null;
  order?: number;
  CaseParentCategoryTranslations?: CaseParentCategoryTranslation[];
  CaseCategoryTranslations?: CaseCategoryTranslation[];
  CaseSubCategoryTranslations?: CaseSubCategoryTranslation[];
}

export interface CategoryTranslationItem {
  name: string;
  icon: string | null;
  placeholderTitle: string;
  locale: string;
  caseParentCategoryTranslationId?: number | null;
  caseCategoryTranslationId?: number | null;
  caseSubCategoryTranslationId?: number | null;
}

export interface CaseParentCategoryTranslation extends Partial<TimeStamp> {
  caseParentCategoryTranslationId?: number | null;
  caseParentCategoryId?: string;
  name: string;
  icon: string | null;
  placeholderTitle: string;
  locale: string;
}

export interface CaseCategoryTranslation extends Partial<TimeStamp> {
  caseCategoryTranslationId?: number | null;
  caseCategoryId?: string;
  name: string;
  icon: string | null;
  placeholderTitle: string;
  locale: string;
}

export interface CaseSubCategoryTranslation extends Partial<TimeStamp> {
  caseSubCategoryTranslationId?: number | null;
  caseSubCategoryId?: string;
  name: string;
  icon: string | null;
  placeholderTitle: string;
  locale: string;
}

export interface CustomCategory extends CategoryBase {
  caseParentCategoryId?: string;
  caseCategoryId?: string;
  caseSubCategoryId?: string;
  id: string;
  CaseCategories?: CaseCategory[];
  CaseSubCategories?: CaseSubCategory[];
  CaseParentCategoryTranslations?: CaseParentCategoryTranslation[];
  CaseCategoryTranslations?: CaseCategoryTranslation[];
  CaseSubCategoryTranslations?: CaseSubCategoryTranslation[];
}

export interface GetCategotyListFilter extends Filter<CustomCategory> {
  caseParentCategoryId?: string;
  caseCategoryId?: string;
  caseSubCategoryId?: string;
  page?: number;
}

export enum CategoryOptions {
  'parent' = 'Parent Category',
  'cat' = 'Case Category',
  'subCat' = 'Sub Category',
}

export type CategoryType = keyof typeof CategoryOptions;

export interface CustomFAQ extends FAQ {
  id: string;
}
export interface GetFAQListFilter extends Filter<FAQ> {
  page?: number;
  caseCategoryId?: string;
  caseParentCategoryId?: string;
  caseSubCategoryId?: string;
  q?: string;
}

export interface CaseCategoryFAQData extends TimeStamp, CaseCategoryFAQ {
  faqId: number;
}

export interface CaseCategoryFAQ {
  caseCategoryFAQId: number | string | null;
  caseParentCategoryId: number | string | null;
  caseCategoryId: number | string | null;
  caseSubCategoryId: number | string | null;
}
