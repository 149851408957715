import { Filter, ListResult } from 'app/models';
import {
  LaundryData,
  Property,
  PropertyData,
  RatePlan,
  WebSiteRooms,
} from 'app/models/Property';

import Axios from './';

/**
* * get all Property List (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/bookings/property)
+*/

export interface GetPropertyListParams extends Filter<PropertyData> {}

export const getPropertyList = async (params: GetPropertyListParams) => {
  const { data } = await Axios.get<ListResult<PropertyData>>(
    '/bookings/property',
    { params },
  );
  return data;
};

/**
* * get temp Property short List (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/properties/shortProperty)
+*/

export interface GetPropertyShortListParams extends Filter<Property> {}

export const getPropertyShortList = async (
  params: GetPropertyShortListParams,
) => {
  const { data } = await Axios.get<ListResult<Property>>(
    '/properties/shortProperty',
    {
      params,
    },
  );
  return data;
};

/**
* * search one Property unit v2 (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/bookings/searchRoom)
+*/

export interface GetOnePropertyUnitParams extends Filter<WebSiteRooms> {
  districtIds: number;
  bathroomTypes: any;
  propertyIds: any;
  excludePropertyIds: any;
  sellingNames: any;
  sizeFrom: number;
  sizeTo: number;
  rentFrom: number;
  rentTo: number;
  requiredPetFriendly: boolean;
  occupancy: any;
  estimatedMoveInDate: string;
  estimatedMoveOutDate: string;
}

export const getOnePropertyUnit = async (params: GetOnePropertyUnitParams) => {
  const { data } = await Axios.get<ListResult<WebSiteRooms>>(
    '/bookings/searchRoom',
    {
      params,
    },
  );
  return data;
};

/**
* * get one Property (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/bookings/property/-propertyId-)
+*/

export const getOneProperty = async (propertyId: string) => {
  const { data } = await Axios.get<PropertyData>(
    `/bookings/property/${propertyId}`,
  );
  return data;
};

/**
* * get one Property Laundry Setting (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/laundry/property/-propertyId-)
+*/

export const getOneLaundrySetting = async (propertyId: string) => {
  const { data } = await Axios.get<LaundryData>(
    `laundry/property/${propertyId}`,
  );
  return data;
};

/**
* * update one Property color (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#patch-/v1/properties/property/-propertyId-)
+*/

export interface UpdateOnePropertyColorParams {
  color: string;
}

export const updateOnePropertyColor = async (
  propertyId: string,
  params: UpdateOnePropertyColorParams,
) => {
  const { data } = await Axios.patch<UpdateOneLaundrySettingParams>(
    `/properties/property/${propertyId}`,
    params,
  );
  return data;
};

/**
* * update one Property Laundry Setting (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#put-/v1/laundry)
+*/

export interface UpdateOneLaundrySettingParams {
  propertyId: string;
  kioskSn: string;
  endpoint: 'new' | 'old';
  isUnderMaintenance: boolean;
}

export const updateOneLaundrySetting = async (
  params: UpdateOneLaundrySettingParams,
) => {
  const { data } = await Axios.put<UpdateOneLaundrySettingParams>(
    `/laundry`,
    params,
  );
  return data;
};

/**
* * list rate plans (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/properties/ratePlans)
+*/

export interface ListRoomRatePlansParams extends Filter<RatePlan> {
  sellingName: string;
}

export const listRoomRatePlans = async (params: ListRoomRatePlansParams) => {
  const { data } = await Axios.get<ListResult<RatePlan>>(
    '/properties/ratePlans',
    { params },
  );
  return data;
};

/**
* * sync all property info (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/properties/sync)
+*/

// ! add extra timeout time. i.e. 10 mins
export const syncAllProperty = async () => {
  const { data } = await Axios.get<any>('/properties/sync', {
    timeout: 1000 * 60 * 10,
  });
  return data;
};

/**
* * sync property info (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#post-/v1/properties/property/-propertyId-/async)
+*/

export const syncProperty = async (propertyId: string) => {
  const { data } = await Axios.post<any>(
    `/properties/property/${propertyId}/async`,
  );
  return data;
};

export interface UpdateOnePropertyParams {
  visitorTerm: string;
  visitorIdentificationDocumentText: string;
  PropertyTranslations: {
    propertyTranslationId?: number;
    visitorTerm: string;
    visitorIdentificationDocumentText: string;
    locale: string;
  }[];
}

export const updateOneProperty = async (
  propertyId,
  params: UpdateOnePropertyParams,
) => {
  const { data } = await Axios.patch<UpdateOneLaundrySettingParams>(
    `/properties/property/${propertyId}`,
    params,
  );
  return data;
};
