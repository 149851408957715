/**
 *
 * FaqList
 */
import * as Sentry from '@sentry/react';
import { CaseCategoryBreadCrumb } from 'app/components/CasePage';
import TableCount from 'app/components/Common/TableCount';
import { SortableTable } from 'app/components/Table';
import { APP_NAME, FAQ_PAGE_SIZE } from 'app/config';
import { useTableData } from 'app/hooks';
import {
  CategoryOptions,
  CategoryType,
  CustomFAQ,
  GetFAQListFilter,
} from 'app/models';
import { Property } from 'app/models/Property';
// service
import 'app/services';
import {
  getFAQListByCategoryId,
  updateOneOrderFAQByID,
} from 'app/services/CaseServices';
import {
  GetPropertyShortListParams,
  getPropertyShortList,
} from 'app/services/PropertyServices';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { columns } from './FAQListColumns';

interface FAQListParams {
  type: CategoryType;
  id: string;
}

export const FaqListPage = () => {
  const params = useParams<FAQListParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [filter, setFilter] = useState<GetFAQListFilter | null>(null);
  const [FAQList, setFAQList] = useState<CustomFAQ[]>([]);
  const [originalFAQList, setOriginalFAQList] = useState<CustomFAQ[]>([]);

  const { dataList: propertyList } = useTableData<
    GetPropertyShortListParams,
    Property
  >({
    call: getPropertyShortList,
    filterVariableArray: ['asc', 'desc'],
  });

  const getAllFAQ = useCallback(async () => {
    setLoading(true);
    if (filter) {
      try {
        const faqRes = await getFAQListByCategoryId(filter);
        const { count, rows } = faqRes;
        setCount(count);
        const faqArr = rows.map(row => ({
          ...row,
          id: row.faqId.toString(),
        }));
        setFAQList(faqArr);
        setOriginalFAQList(faqArr);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
        toast.error('Fail to load FAQ, please try again.');
      } finally {
        setLoading(false);
      }
    }
  }, [filter]);

  useEffect(() => {
    getAllFAQ();
  }, [getAllFAQ]);

  useEffect(() => {
    const { id, type } = params;
    if (type && id && CategoryOptions[type]) {
      const tempFilter: GetFAQListFilter = {
        limit: FAQ_PAGE_SIZE,
        offset: 0,
        asc: 'order',
      };
      if (type === 'cat') {
        tempFilter.caseCategoryId = id;
      } else if (type === 'subCat') {
        tempFilter.caseSubCategoryId = id;
      } else if (type === 'parent') {
        tempFilter.caseParentCategoryId = id;
      }
      setFilter(tempFilter);
    }
  }, [params]);

  const saveOnPress = async () => {
    setLoading(true);
    try {
      const promiseArr: Promise<any>[] = [];
      FAQList.forEach((faq, order) => {
        updateOneOrderFAQByID(faq.faqId, { order });
      });
      await Promise.all(promiseArr);
      setOriginalFAQList(FAQList);
      toast.success('Update position successfully.');
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      toast.error('Fail to update position, please try again.');
    } finally {
      setLoading(false);
    }
  };

  let isPositionUpdated = false;
  for (let i = 0; i < originalFAQList.length; i++) {
    if (originalFAQList[i].id !== FAQList[i].id) {
      isPositionUpdated = true;
      break;
    }
  }

  return (
    <div className="page-content">
      <Helmet>
        <title>Faq List | {APP_NAME}</title>
      </Helmet>
      <Container fluid>
        <CaseCategoryBreadCrumb />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <>
                  <Row>
                    <Col sm={12}>
                      <div className="d-flex flex-row-reverse">
                        <button
                          className="btn btn-success ms-2"
                          disabled={loading || !isPositionUpdated}
                          onClick={() => saveOnPress()}
                        >
                          {loading ? (
                            <i className="bx bx-loader-circle bx-spin" />
                          ) : (
                            <i className="mdi mdi-content-save-outline" />
                          )}

                          {` ${'Save Position'}`}
                        </button>
                        <a
                          href="./faq/new"
                          className="btn btn-primary"
                          role="button"
                          aria-pressed="true"
                        >
                          <i className="mdi mdi-plus" />
                          {` ${'Add FAQ'}`}
                        </a>
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      className="d-flex align-self-end justify-content-end"
                    >
                      <TableCount count={count} title="No. of FAQ" />
                    </Col>
                    <Col xl="12">
                      <div className="table-responsive">
                        <SortableTable
                          data={FAQList || []}
                          columns={columns(propertyList)}
                          moveRows={(data: CustomFAQ[]) => {
                            setFAQList(
                              data.map((item, index) => ({
                                ...item,
                                order: index,
                              })),
                            );
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
