import { OptionItems } from 'app/models';

import '../../assets/scss/custom.scss';

interface Props {
  options: OptionItems[];
  onChange: (lang: string) => void;
  value: string;
}

export const LangTabSelect = (props: Props) => {
  const { options, onChange, value } = props;
  return (
    <div className="lang-tab-select-container d-flex">
      {options.map((option, index) => (
        <button
          type="button"
          key={index}
          className="custom-btn d-flex"
          onClick={() => onChange(option.value)}
        >
          {option.label}
          <div
            className={`dot ${option.value === value ? 'active' : ''}`}
          ></div>
        </button>
      ))}
    </div>
  );
};
