import { OptionItems } from 'app/models';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  isClearable?: boolean;
  disabled?: boolean;
  inputValue?: string;
  onInputChange?: (newValue: string) => void;
  selectAllBtnText?: string;
  selectAllBtnFn?: () => void;
  onChange?: (e: any) => void;
}

const AddOnMultipleSelectField = (
  props: OtherProps & FieldHookConfig<string[]>,
) => {
  const [field, meta, helpers] = useField(props);

  const {
    label,
    placeholder,
    options,
    isClearable,
    disabled,
    inputValue,
    onInputChange,
    selectAllBtnText,
    selectAllBtnFn,
    onChange,
  } = props;

  const selectedValue = options.filter(option => {
    return field.value && field.value.includes(option.value);
  });

  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'has-danger' : ''
      }`}
      // style={{ height: 85 }}
    >
      {label ? (
        <div className="d-flex justify-content-between">
          <Label>{props.label}</Label>
          <div
            className="text-primary"
            style={{ cursor: 'pointer' }}
            onClick={() => !disabled && selectAllBtnFn?.()}
          >
            {selectAllBtnText}
          </div>
        </div>
      ) : null}
      <Select
        isMulti={true}
        placeholder={placeholder}
        options={options}
        classNamePrefix="select2-selection"
        {...field}
        value={selectedValue}
        onChange={(e: any) => {
          if (onChange) {
            onChange(e);
          } else {
            helpers.setValue(e.map((item: OptionItems) => item.value));
          }
        }}
        isClearable={isClearable}
        isDisabled={disabled}
        inputValue={inputValue}
        onInputChange={onInputChange}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default AddOnMultipleSelectField;
