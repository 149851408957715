import { FAQFormItems } from 'app/components/FAQ/FAQForm';
import { LANG_CONFIG } from 'app/config/languages';
import { FAQFormType } from 'app/constants/FAQ';
import {
  CaseCategoryFAQs,
  FAQ,
  FAQData,
  FAQTranslations,
  OptionItems,
} from 'app/models';
import { Property } from 'app/models/Property';
import _ from 'lodash';

const faqFormItemTemplate: FAQFormItems = {
  title: '',
  content: '',
  caseParentCategoryId: null,
  caseCategoryId: null,
  caseSubCategoryId: null,
  order: 0,
  propertyIds: [],
  caseItems: [],
};

export const formatFAQFormData = (values: FAQFormType): FAQData => {
  const data: FAQData = {
    ...values.en!,
    propertyIds: values.en!.propertyIds.map(propertyId => propertyId.value),
    CaseCategoryFAQs: values.en!.caseItems.map(item => {
      const tempCaseData: Omit<
        CaseCategoryFAQs,
        'createdAt' | 'deletedAt' | 'updatedAt'
      > = {};
      if (item.caseParentCategoryId) {
        tempCaseData.caseParentCategoryId = item.caseParentCategoryId;
      }
      if (item.caseCategoryId) {
        tempCaseData.caseCategoryId = item.caseCategoryId;
      }
      if (item.caseCategoryFAQId) {
        tempCaseData.caseCategoryFAQId = item.caseCategoryFAQId;
      }
      return tempCaseData;
    }),
    FAQTranslations: LANG_CONFIG.filter(
      lang => lang !== 'en' && (values[lang]?.title || values[lang]?.content),
    ).map(lang => ({
      title: values[lang].title,
      locale: lang,
      content: values[lang].content,
    })),
  };
  return data;
};

export const transformFAQToFormData = (
  properties: Property[],
  data?: FAQ,
): FAQFormType => {
  let enForm = { ..._.cloneDeep(faqFormItemTemplate) };
  let FAQTranslations: FAQTranslations[] = [];
  if (data) {
    const { title, content, propertyIds, CaseCategoryFAQs } = data;
    let tempOptionItemArr: OptionItems[] | null = null;
    if (propertyIds && propertyIds.length > 0) {
      const tempArr = propertyIds.map(property => {
        const tempMapArr = properties.filter(
          item => item.propertyId === property,
        );
        const params = {
          label: tempMapArr && tempMapArr?.[0].propertyName.split(' – ')[1],
          value: property,
        };
        return params;
      });
      tempOptionItemArr = tempArr;
    }
    enForm = {
      title,
      content,
      caseParentCategoryId: null,
      caseCategoryId: null,
      caseSubCategoryId: null,
      order: 0,
      propertyIds: tempOptionItemArr ? tempOptionItemArr : [],
      caseItems: CaseCategoryFAQs
        ? CaseCategoryFAQs.map(item => ({
            caseParentCategoryId: item.caseParentCategoryId,
            caseCategoryFAQId: item.caseCategoryFAQId,
            caseCategoryId: item.caseCategoryId ? item.caseCategoryId : '',
            caseSubCategoryId: null,
          }))
        : [],
    };
    FAQTranslations = data.FAQTranslations;
  }

  const tempForm: FAQFormType = LANG_CONFIG.reduce((prev, curr) => {
    let temp = { ..._.cloneDeep(faqFormItemTemplate) };
    const index = _.findIndex(FAQTranslations, trans => trans.locale === curr);
    if (index > -1) {
      temp = {
        title: FAQTranslations[index].title,
        content: FAQTranslations[index].content,
        caseParentCategoryId: null,
        caseCategoryId: null,
        caseSubCategoryId: null,
        order: 0,
        propertyIds: [],
        caseItems: [],
      };
    }
    if (curr === 'en') {
      temp = { ...enForm };
    }
    return {
      ...prev,
      [curr]: temp,
    };
  }, {});
  return tempForm;
};
