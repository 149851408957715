import 'flatpickr/dist/themes/material_blue.css';
import { FieldHookConfig, useField } from 'formik';
import Flatpickr from 'react-flatpickr';
import { InputGroup, Label } from 'reactstrap';

interface OtherProps {
  label: string;
  placeholder?: string;
  minDate?: Date | Date[] | string[] | null;
  maxDate?: Date | Date[] | string[] | null;
  onChange?: (value) => void;
  enableTimeInCAL?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

const DateField = (props: OtherProps & FieldHookConfig<Date[] | null>) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    minDate,
    maxDate,
    onChange,
    enableTimeInCAL,
    disabled,
    isLoading,
  } = props;
  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'text-danger' : ''
      }`}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <InputGroup>
        <Flatpickr
          className="form-control d-block"
          placeholder={placeholder || 'Date'}
          options={{
            altInput: true,
            altFormat: enableTimeInCAL ? 'd-m-Y H:i' : 'd-m-Y',
            dateFormat: enableTimeInCAL ? 'd-m-Y H:i' : 'd-m-Y',
            minDate: minDate ? minDate[0] : null,
            maxDate: maxDate ? maxDate[0] : null,
            enableTime: enableTimeInCAL ? true : false,
          }}
          disabled={disabled}
          value={field.value || undefined}
          onChange={date => {
            if (onChange) {
              onChange(date);
            } else {
              if (date[0]) {
                helpers.setValue(date);
              } else {
                helpers.setValue(null);
              }
            }
          }}
        />
        <div
          className="position-absolute d-flex align-items-center"
          style={{
            bottom: '5px',
            right: '10px',
            zIndex: 999,
          }}
        >
          {isLoading && (
            <div
              className="me-1"
              style={{
                cursor: 'pointer',
              }}
            >
              <i className="bx bx-loader-circle bx-spin fs-5" />
            </div>
          )}
        </div>
      </InputGroup>
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default DateField;
