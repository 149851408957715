import { ColumnDefinitionType, TableRow } from 'app/models';
import update from 'immutability-helper';
import { useCallback } from 'react';

import TableRowItem from './TableRowItem';

type TableRowsProps<T extends TableRow, K extends keyof T> = {
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T, K>>;
  moveRows?: (data: T[]) => void;
};

const TableRows = <T extends TableRow, K extends keyof T>({
  data,
  columns,
  moveRows,
}: TableRowsProps<T, K>): JSX.Element => {
  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newDataOrder = update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, data[dragIndex]],
        ],
      });
      moveRows && moveRows(newDataOrder);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const rows = data.map((row, index) => {
    return (
      <TableRowItem
        key={row.id}
        id={row.id}
        index={index}
        columns={columns}
        row={row}
        moveRow={moveItem}
      />
    );
  });

  return <tbody>{rows}</tbody>;
};

export default TableRows;
