/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import NonAuthLayout from 'app/components/NonAuthLayout';
// layouts Format
import VerticalLayout from 'app/components/VerticalLayout';
import { TOAST_TIME } from 'app/config';
import ProtectedRoute from 'app/route/ProtectedRoute';
import { authRoutes, userRoutes } from 'app/route/allRoutes';
import { AxiosInterceptor } from 'app/services';
// import rc-tooltip css
import 'rc-tooltip/assets/bootstrap.css';
import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
// toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from 'store';
import { GlobalStyle } from 'styles/global-styles';

import { useAuth } from './hooks/Authentication';

export function App() {
  useAuth();
  const { layoutType } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  // const { i18n } = useTranslation();
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (layoutType) {
      case 'horizontal':
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  const Layout = getLayout();
  return (
    <HelmetProvider>
      <Helmet>
        <title>Weave Living Admin</title>
        <meta name="description" content="Admin Panel" />
      </Helmet>
      <AxiosInterceptor>
        <Switch>
          {authRoutes.map((route, idx) => (
            <ProtectedRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact={route.exact}
            />
          ))}
          {userRoutes.map((route, idx) => (
            <ProtectedRoute
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          <Redirect from="*" to="/notFound" />
        </Switch>
      </AxiosInterceptor>
      <ToastContainer
        position="top-right"
        autoClose={TOAST_TIME}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GlobalStyle />
    </HelmetProvider>
  );
}
