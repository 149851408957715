import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6} sm={0}></Col>
            <Col md={6} sm={12}>
              <p className="text-center text-sm-end ">
                {new Date().getFullYear()} © AppicIDEA
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
