import {
  CaseCategory,
  CaseSubCategory,
  FAQ,
  FAQData,
  GetCategotyListFilter,
  GetFAQListFilter,
  ListResult,
  ParentCategory,
  UpdateOneCategoryParams,
} from 'app/models';

import Axios from './';

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/cases/parentCategory
+*/

export const getAllParentCategory = async (params: GetCategotyListFilter) => {
  const { data } = await Axios.get<ListResult<ParentCategory>>(
    '/cases/parentCategory',
    { params },
  );
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/cases/parentCategory/-caseParentCategoryId-
+*/

export const getOneParentCategory = async (caseParentCategoryId: string) => {
  const { data } = await Axios.get<ParentCategory>(
    `/cases/parentCategory/${caseParentCategoryId}`,
  );
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#patch-/v1/cases/parentCategory/-caseParentCategoryId-
+*/

export const updateOneParentCategory = async (
  caseParentCategoryId: string,
  params: UpdateOneCategoryParams,
) => {
  const { data } = await Axios.patch<any>(
    `/cases/parentCategory/${caseParentCategoryId}`,
    params,
  );
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/cases/category
+*/

export const getAllCaseCategory = async (params: GetCategotyListFilter) => {
  const { data } = await Axios.get<ListResult<CaseCategory>>(
    '/cases/category',
    {
      params,
    },
  );
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/cases/category/-caseCategoryId-
+*/

export const getOneCaseCategory = async (caseCategoryId: string) => {
  const { data } = await Axios.get<CaseCategory>(
    `/cases/category/${caseCategoryId}`,
  );
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#patch-/v1/cases/category/-caseCategoryId-
+*/

export const updateOneCaseCategory = async (
  caseCategoryId: string,
  params: UpdateOneCategoryParams,
) => {
  const { data } = await Axios.patch<any>(
    `/cases/category/${caseCategoryId}`,
    params,
  );
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/cases/subCategory
+*/

export const getAllCaseSubCategory = async (params: GetCategotyListFilter) => {
  const { data } = await Axios.get<ListResult<CaseSubCategory>>(
    '/cases/subCategory',
    {
      params,
    },
  );
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#patch-/v1/cases/subCategory/-caseSubCategoryId-
+*/

export const updateOneCaseSubCategory = async (
  caseSubCategoryId: string,
  params: UpdateOneCategoryParams,
) => {
  const { data } = await Axios.patch<any>(
    `/cases/subCategory/${caseSubCategoryId}`,
    params,
  );
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/cases/subCategory/-caseSubCategoryId-
+*/

export const getOneCaseSubCategory = async (caseSubCategoryId: string) => {
  const { data } = await Axios.get<CaseSubCategory>(
    `/cases/subCategory/${caseSubCategoryId}`,
  );
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/cases/faq
+*/

export const getFAQListByCategoryId = async (params: GetFAQListFilter) => {
  const { data } = await Axios.get<ListResult<FAQ>>('/cases/faq', { params });
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#post-/v1/cases/faq
+*/

export const createOneFaq = async (params: FAQData) => {
  const { data } = await Axios.post<any>('/cases/faq', params);
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/cases/faq/-faqId-
+*/

export const getOneFAQByID = async (faqId: number | string) => {
  const { data } = await Axios.get<FAQ>(`/cases/faq/${faqId}`);
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#delete-/v1/cases/faq/-faqId-
+*/

export const updateOneFAQByID = async (
  faqId: number | string,
  params: FAQData,
) => {
  const { data } = await Axios.patch<FAQ>(`/cases/faq/${faqId}`, params);
  return data;
};

export const updateOneOrderFAQByID = async (
  faqId: number | string,
  params: Partial<FAQData>,
) => {
  const { data } = await Axios.patch<FAQ>(`/cases/faq/${faqId}`, params);
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#delete-/v1/cases/faq/-faqId-
+*/

export const deleteOneFAQByID = async (faqId: number | string) => {
  const { data } = await Axios.delete<FAQ>(`/cases/faq/${faqId}`);
  return data;
};

/**
* * https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/cases/sync
+*/

export const syncAllCases = async () => {
  const { data } = await Axios.get<any>('/cases/sync', {
    timeout: 1000 * 60 * 10,
  });
  return data;
};
