/**
 *
 * FaqForm
 *
 */
import {
  HTMLField,
  InputField,
  MultipleSelectField,
  SelectField,
} from 'app/components/Form';
import { LANG_CONFIG } from 'app/config/languages';
import { FAQFormType } from 'app/constants/FAQ';
import { CaseCategoryFAQsData, OptionItems } from 'app/models';
import { FieldArray, Form, Formik, FormikErrors } from 'formik';
import { Fragment, useState } from 'react';
import { Button, CardTitle, Col, Row } from 'reactstrap';
import * as Yup from 'yup';

import { LangTabSelect } from '../Common/LangTabSelect';

// form

// form schema

const FAQ_SCHEMA_NECESSARY = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .max(150, 'Max. 150 characters')
    .required('Required'),
  content: Yup.string()
    .nullable()
    .max(10000, 'Maximum 10,000 characters')
    .required('Required'),
  caseItem: Yup.array().of(
    Yup.object().shape({
      caseParentCategoryId: Yup.string().nullable().required('Required'),
    }),
  ),
});

const FAQ_SCHEMA_OPTIONAL = Yup.object().shape({
  title: Yup.string().nullable().max(150, 'Max. 150 characters'),
  content: Yup.string().nullable().max(10000, 'Maximum 10,000 characters'),
});

const SCHEMA_OBJ = LANG_CONFIG.reduce(
  (prev, current) =>
    current === 'en'
      ? { ...prev, [current]: FAQ_SCHEMA_NECESSARY }
      : { ...prev, [current]: FAQ_SCHEMA_OPTIONAL },
  {},
);

const SCHEMA = Yup.object(SCHEMA_OBJ);

export type FAQFormItems = {
  title: string;
  content: string;
  caseParentCategoryId: null;
  caseCategoryId: null;
  caseSubCategoryId: null;
  order: number;
  propertyIds: OptionItems[];
  caseItems: CaseCategoryFAQsData[];
};

interface Props {
  faqId: number | null;
  form: FAQFormType;
  propertyList: OptionItems[];
  parentCategory: OptionItems[];
  caseCategoryMap: Record<string, OptionItems[]>;
  loading: boolean;
  onSubmit: (values: FAQFormType) => void;
  getCaseCategory: (caseParentCategoryId: string | null) => void;
  setDeleteVisible: (bool: boolean) => void;
}

export const FaqForm = ({
  faqId,
  form,
  propertyList,
  parentCategory,
  caseCategoryMap,
  loading,
  onSubmit,
  getCaseCategory,
  setDeleteVisible,
}: Props) => {
  const [activeLang, setActiveLang] = useState<string>('en');
  const parentCategoryOnChange = (
    index: number,
    option: OptionItems,
    setFieldValue: (field: string, value: string) => void,
  ) => {
    if (option && option.value) {
      setFieldValue(
        `en.caseItems.[${index}].caseParentCategoryId`,
        option.value,
      );
      getCaseCategory(option.value);
    } else {
      setFieldValue(`en.caseItems.[${index}].caseParentCategoryId`, '');
      getCaseCategory(null);
    }
    setFieldValue(`en.caseItems.[${index}].caseCategoryId`, '');
  };

  const renderError = (errors: FormikErrors<FAQFormType>) => {
    const temp: string[] = [];
    Object.keys(errors).forEach((lang, index) => {
      if (lang !== activeLang) {
        temp.push(lang.toUpperCase());
      }
    });
    return temp.length
      ? `Error(s) occured in ${temp.map(lang => lang)} form. Please check.`
      : '';
  };

  return (
    <>
      <div className="p-2">
        <Formik
          initialValues={form}
          validationSchema={SCHEMA}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values, errors }) => (
            <Form className="form-horizontal">
              {/* <div>
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </div>
              <div>
                <pre>{JSON.stringify(errors, null, 2)}</pre>
              </div> */}
              <Row className="mb-3">
                <Col
                  sm={12}
                  className="d-flex justify-content-end align-items-center"
                >
                  <LangTabSelect
                    options={LANG_CONFIG.map(lang => ({
                      label: lang.toLocaleUpperCase(),
                      value: lang,
                    }))}
                    value={activeLang}
                    onChange={lang => setActiveLang(lang)}
                  />
                </Col>
                <Col
                  sm={12}
                  className="d-flex justify-content-end align-items-center"
                >
                  <div className="text-danger mt-1">{renderError(errors)}</div>
                </Col>
              </Row>
              {LANG_CONFIG.map(lang => (
                <Fragment key={lang}>
                  <Row className={lang === activeLang ? 'd-flex' : 'd-none'}>
                    <Col lg={6}>
                      <InputField
                        name={`${lang}.title`}
                        label="FAQ Title"
                        placeholder="FAQ title"
                        type="text"
                      />
                    </Col>
                    <Col lg={6}>
                      <MultipleSelectField
                        name="en.propertyIds"
                        label="Eligible location (Allow multiple)"
                        placeholder="Eligible location"
                        options={propertyList}
                      />
                    </Col>
                    <Col lg={12}>
                      <HTMLField name={`${lang}.content`} label="Content" />
                    </Col>
                  </Row>
                </Fragment>
              ))}

              <Row className="mt-4">
                <CardTitle>Select Case</CardTitle>
                <hr />
                <Col md={12}>
                  <FieldArray
                    name="en.caseItems"
                    render={arrayHelpers => (
                      <>
                        {values?.en &&
                          values.en.caseItems.map((item, index) => (
                            <Row key={index}>
                              <Col lg={5}>
                                <SelectField
                                  label="Parent Case"
                                  name={`en.caseItems.[${index}].caseParentCategoryId`}
                                  options={parentCategory}
                                  isClearable
                                  onChange={e =>
                                    parentCategoryOnChange(
                                      index,
                                      e,
                                      setFieldValue,
                                    )
                                  }
                                />
                              </Col>

                              <Col lg={5}>
                                <SelectField
                                  label="Child Case (Optional)"
                                  name={`en.caseItems.[${index}].caseCategoryId`}
                                  options={
                                    item.caseParentCategoryId &&
                                    caseCategoryMap?.[item.caseParentCategoryId]
                                      ? caseCategoryMap?.[
                                          item.caseParentCategoryId
                                        ]
                                      : []
                                  }
                                  isClearable
                                />
                              </Col>
                              {values.en && values.en.caseItems.length > 1 ? (
                                <Col lg={2}>
                                  <button
                                    className="btn"
                                    style={{ marginTop: 28 }}
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <i className="mdi mdi-close-thick" />
                                  </button>
                                </Col>
                              ) : null}
                            </Row>
                          ))}
                        <Button
                          type="button"
                          color="warning"
                          className="ms-2"
                          style={{ float: 'right' }}
                          onClick={() =>
                            arrayHelpers.push({
                              caseParentCategoryId: '',
                              caseCategoryId: '',
                              caseSubCategoryId: '',
                            })
                          }
                        >
                          Add one Case
                        </Button>
                      </>
                    )}
                  />
                </Col>
              </Row>

              <div className="mt-3 d-flex flex-row-reverse">
                <Button
                  type="submit"
                  color="primary"
                  className="ms-1"
                  disabled={loading}
                >
                  {loading ? (
                    <i className="bx bx-loader-circle bx-spin" />
                  ) : (
                    'Save'
                  )}
                </Button>
                {faqId ? (
                  <Button
                    type="button"
                    color="danger"
                    className="ms-1"
                    onClick={() => setDeleteVisible(true)}
                  >
                    Delete
                  </Button>
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
