import { OptionItems } from 'app/models';
import { FieldHookConfig, useField } from 'formik';
import _ from 'lodash';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { Label } from 'reactstrap';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (str: any) => void;
  isClearable?: boolean;
  disabled?: boolean;
  isMulti?: boolean;
  formatCreateLabel?: (str) => React.ReactNode;
  isInModal?: boolean;
  onMenuScrollToBottom?: () => void;
}

const CreatableSelectField = (props: OtherProps & FieldHookConfig<any>) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    options,
    onChange,
    isClearable,
    disabled,
    isMulti,
    formatCreateLabel,
    isInModal = false,
    onMenuScrollToBottom,
  } = props;
  const rendervalue = () => {
    const index = _.findIndex(options, option => option.value === field.value);
    if (index > -1) {
      return options[index];
    } else if (field.value !== '') {
      return { value: field.value, label: field.value };
    } else {
      return '';
    }
  };

  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'text-danger' : ''
      }`}
    >
      {label ? <Label>{props.label}</Label> : null}
      <CreatableSelect
        isMulti={isMulti}
        defaultValue={isMulti ? field.value : rendervalue()}
        placeholder={placeholder}
        options={options}
        className={`${isMulti ? 'basic-multi-select' : 'basic-single'}`}
        classNamePrefix={`${isMulti ? '' : 'select2-selection'}`}
        formatCreateLabel={formatCreateLabel}
        {...field}
        onChange={e => {
          if (onChange) {
            onChange(isMulti ? e : e.value);
          } else {
            if (e) {
              helpers.setValue(isMulti ? e : e.value);
            } else {
              helpers.setValue('');
            }
          }
        }}
        value={isMulti ? field.value : rendervalue()}
        isClearable={isClearable}
        isDisabled={disabled}
        createOptionPosition="first"
        menuPortalTarget={!isInModal ? document.body : undefined}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        onMenuScrollToBottom={onMenuScrollToBottom}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CreatableSelectField;
