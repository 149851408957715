import { Filter, ListResult, User } from '../models';
import Axios from './';

/**
 * * Get User list (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/users)
 +*/
export interface GetUserListParams extends Filter<User> {
  page?: number;
  q?: string;
  email?: string;
  accountType?: 'WebSiteOnly' | 'AppWebSite';
}

export const getUserList = async (params: GetUserListParams) => {
  const { data } = await Axios.get<ListResult<User>>('/users', { params });
  return data;
};

/**
* * Get One User (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/users/-userId-)
+*/

export const getOneUser = async (userId: number) => {
  const { data } = await Axios.get<User>(`/users/${userId}`);
  return data;
};

/**
* * Create User By Admin, Admin Only (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#post-/v1/users)
+*/

export interface CreateUserParams {
  email: string;
  isReceivedInformation: boolean;
  firstName: string;
  lastName: string;
  prefix: string;
  mobile: string;
  gender: string;
  dateOfBirth: string;
  currentLivingLocation: string;
  password: string;
}

export const createUser = async (params: CreateUserParams) => {
  const { data } = await Axios.post<any>(`/users`, params);
  return data;
};

export const resetUserPassword = async (
  userId: string | number,
  newPassword: string,
) => {
  const { data } = await Axios.post<any>(`/users/${userId}/resetPassword`, {
    newPassword,
  });
  return data;
};

export interface UpdateUserParams extends Partial<CreateUserParams> {
  contactGuid?: string;
}

export const updateOneUserById = async (
  userId: string | number,
  params: UpdateUserParams,
) => {
  const { data } = await Axios.patch<any>(`/users/${userId}`, params);
  return data;
};
