export const API_URL =
  process.env.REACT_APP_API_URL || 'https://weaveliving-app-dev.appicidea.com';
export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const PAGE_SIZE = 20;
export const FAQ_PAGE_SIZE = 99;
export const GENERAL_PAGE_SIZE = 99;
export const TOAST_TIME = 5000;
export const APP_NAME = 'Weave Living Admin';
export const IMAGE_SIZE = 1024 * 10000;
