import { LANG_CONFIG } from 'app/config/languages';
import { CaseCategoryFAQsData, OptionItems } from 'app/models';

const FORM_VALUES = {
  title: '',
  content: '',
  caseParentCategoryId: null,
  caseCategoryId: null,
  caseSubCategoryId: null,
  order: 0,
  propertyIds: [],
  caseItems: [
    {
      caseParentCategoryId: null,
      caseCategoryId: null,
      caseSubCategoryId: null,
    },
  ],
};

export const DEFAULT_FAQ_FORM_VALUE: FAQFormType = LANG_CONFIG.reduce(
  (prev, current) => ({
    ...prev,
    [current]: { ...FORM_VALUES },
  }),
  {},
);

export type FAQFormItems = {
  title: string;
  content: string;
  caseParentCategoryId: null;
  caseCategoryId: null;
  caseSubCategoryId: null;
  order: number;
  propertyIds: OptionItems[];
  caseItems: CaseCategoryFAQsData[];
};

export type FAQFormType = {
  [x: string]: FAQFormItems;
};
