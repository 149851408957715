import { ColumnDefinitionType, TableRow } from 'app/models';
import type { Identifier, XYCoord } from 'dnd-core';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export interface TableRowItemProps<T extends TableRow, K extends keyof T> {
  id: string;
  index: number;
  moveRow?: (dragIndex: number, hoverIndex: number) => void;
  columns: ColumnDefinitionType<T, K>[];
  row: T;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const style = {
  // border: '1px dashed gray',
  // padding: '0.5rem 1rem',
  // marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
  overflow: 'hidden',
  transform: 'translateZ(0)',
};

const TableRowItem = <T extends TableRow, K extends keyof T>({
  id,
  index,
  moveRow,
  columns,
  row,
}: TableRowItemProps<T, K>): JSX.Element => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'table-row',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow && moveRow(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
    canDrop: () => (moveRow ? true : false),
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'table-row',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: moveRow ? true : false,
  });

  const opacity = isDragging ? 0.3 : 1;
  drag(drop(ref));
  return (
    <tr ref={ref} style={{ opacity, ...style }} className="sortable-table-row">
      {columns.map((column, index) => {
        return (
          <td key={`cell-${index}`} data-handler-id={handlerId}>
            {column.formatter ? column.formatter(row) : row[column.key]}
          </td>
        );
      })}
      <td className="sortable-indicator">
        <i className="mdi mdi-dots-vertical text-white" />
      </td>
    </tr>
  );
};

export default TableRowItem;
