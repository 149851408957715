import { Translation } from 'app/models/lang';

export const formatTranslationData = <R, T extends Translation>(
  currentTrans: string,
  getTransData: (trans: T) => R,
  translations?: T[],
) => {
  let temp;
  translations &&
    translations.forEach(trans => {
      if (currentTrans === trans.locale) {
        temp = getTransData(trans);
      }
    });
  return temp;
};
