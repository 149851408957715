/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// Import root app
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App } from 'app';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
// Use consistent styling
import 'sanitize.css/sanitize.css';

// Initialize languages
import './locales/i18n';
import { persistor, store } from './store';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

Sentry.init({
  dsn: 'https://b69658986b8c463d802d106ca7f38c0a@sentry.appicidea.com/4',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV || 'development',
  release: process.env.REACT_APP_SENTRY_REL || 'default',
  enabled: process.env.NODE_ENV !== 'development',
  beforeSend: event => {
    if (window?.location?.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </React.StrictMode>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}
