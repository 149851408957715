import 'app/assets/scss/components/Table/SortableTable.scss';
import { ColumnDefinitionType, TableRow } from 'app/models';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Table } from 'reactstrap';

import TableHeader from './TableHeader';
import TableRows from './TableRows';

type TableProps<T extends TableRow, K extends keyof T> = {
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T, K>>;
  moveRows?: (data: T[]) => void;
};

const style = {
  borderCollapse: 'collapse',
} as const;

const SortableTable = <T extends TableRow, K extends keyof T>({
  data,
  columns,
  moveRows,
}: TableProps<T, K>): JSX.Element => {
  return (
    <Table style={style} className="table align-middle table-nowrap">
      <TableHeader columns={columns} />
      {data.length > 0 ? (
        <DndProvider backend={HTML5Backend}>
          <TableRows data={data} columns={columns} moveRows={moveRows} />
        </DndProvider>
      ) : (
        <tbody>
          <tr>
            <td
              colSpan={columns.length}
              className="fs-4 text-center border-0 text-size-16 fw-bold"
            >
              No Data
            </td>
          </tr>
        </tbody>
      )}
    </Table>
  );
};

export default SortableTable;
