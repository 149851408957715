import * as Sentry from '@sentry/react';
import 'app/assets/scss/components/CasePage/CaseCategoryBreadCrumb.scss';
// import { getImageURL } from 'app/helpers/CommonHelper';
import { CaseCategory, CaseSubCategory, ParentCategory } from 'app/models';
import {
  getOneCaseCategory,
  getOneCaseSubCategory,
  getOneParentCategory,
} from 'app/services/CaseServices';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CaseCategoryBreadCrumbItem } from './CaseCategoryBreadCrumbItem';

interface CaseCategoryBreadCrumbParams {
  parentId: string;
  type: string;
  id: string;
}

export const CaseCategoryBreadCrumb = () => {
  const params = useParams<CaseCategoryBreadCrumbParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [parent, setParent] = useState<ParentCategory | null>(null);
  const [category, setCategroy] = useState<CaseCategory | null>(null);
  const [subCategory, setSubCategroy] = useState<CaseSubCategory | null>(null);

  const getBreadcrumbs = useCallback(async () => {
    setLoading(true);
    const { type, parentId, id } = params;
    try {
      if (id) {
        if (type === 'subCat') {
          const subCategoryRes = await getOneCaseSubCategory(id);
          setSubCategroy(subCategoryRes);
          const { caseCategoryId } = subCategoryRes;
          const categoryRes = await getOneCaseCategory(caseCategoryId);
          setCategroy(categoryRes);
          const { caseParentCategoryId } = categoryRes;
          const parentCategoryRes = await getOneParentCategory(
            caseParentCategoryId,
          );
          setParent(parentCategoryRes);
        } else if (type === 'cat') {
          const categoryRes = await getOneCaseCategory(id);
          setCategroy(categoryRes);
          const { caseParentCategoryId } = categoryRes;
          const parentCategoryRes = await getOneParentCategory(
            caseParentCategoryId,
          );
          setParent(parentCategoryRes);
        } else if (type === 'parent') {
          const parentCategoryRes = await getOneParentCategory(id);
          setParent(parentCategoryRes);
        }
      } else if (type === 'subCat') {
        const categoryRes = await getOneCaseCategory(parentId);
        setCategroy(categoryRes);
        const { caseParentCategoryId } = categoryRes;
        const parentCategoryRes = await getOneParentCategory(
          caseParentCategoryId,
        );
        setParent(parentCategoryRes);
      } else if (type === 'cat') {
        const parentCategoryRes = await getOneParentCategory(parentId);
        setParent(parentCategoryRes);
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    setParent(null);
    setCategroy(null);
    setSubCategroy(null);
    getBreadcrumbs();
  }, [getBreadcrumbs]);

  return (
    <div className="case-category-breadcrumb-container">
      {loading ? (
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="placeholder-container">
            <div className="row">
              <div className={`placeholder-glow h-100 col-3`}>
                <span
                  className={`placeholder col-12`}
                  style={{ height: 39 }}
                ></span>
              </div>
              <div className="placeholder-glow col-9">
                <span className="placeholder col-9"></span>
                <span className="placeholder col-12"></span>
              </div>
            </div>
          </div>

          <div className="arrow-right">
            <i className="mdi mdi-chevron-right font-size-20 text-dark" />
          </div>

          <div className="placeholder-container">
            <div className="row">
              <div className={`placeholder-glow h-100 col-3`}>
                <span
                  className={`placeholder col-12`}
                  style={{ height: 39 }}
                ></span>
              </div>
              <div className="placeholder-glow col-9">
                <span className="placeholder col-9"></span>
                <span className="placeholder col-12"></span>
              </div>
            </div>
          </div>

          <div className="arrow-right">
            <i className="mdi mdi-chevron-right font-size-20 text-dark" />
          </div>

          <div className="placeholder-container">
            <div className="row">
              <div className={`placeholder-glow h-100 col-3`}>
                <span
                  className={`placeholder col-12`}
                  style={{ height: 39 }}
                ></span>
              </div>
              <div className="placeholder-glow col-9">
                <span className="placeholder col-9"></span>
                <span className="placeholder col-12"></span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row align-items-center justify-content-between">
          {parent && (
            <>
              <CaseCategoryBreadCrumbItem
                icon={parent.icon}
                name={parent.name}
                link={`/case-categories`}
                id={parent.caseParentCategoryId}
              />

              {category && (
                <>
                  <div className="arrow-right">
                    <i className="mdi mdi-chevron-right font-size-20 text-dark" />
                  </div>
                  <CaseCategoryBreadCrumbItem
                    icon={category.icon}
                    name={category.name}
                    link={`/case-categories/cat/${category.caseParentCategoryId}`}
                    id={category.caseCategoryId}
                  />

                  {subCategory && (
                    <>
                      <div className="arrow-right">
                        <i className="mdi mdi-chevron-right font-size-20 text-dark" />
                      </div>
                      <CaseCategoryBreadCrumbItem
                        icon={subCategory.icon}
                        name={subCategory.name}
                        link={`/case-categories/subCat/${category.caseCategoryId}`}
                        id={subCategory.caseSubCategoryId}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
