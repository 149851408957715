import {
  Admin,
  AdminLoginPayload,
  AdminType,
  Filter,
  ListResult,
} from '../models';
import Axios from './';

/**
* * get current admin profile (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/admins/me/profile)
+*/

export const getCurrentAdminProfile = async () => {
  const { data } = await Axios.get<Admin>('/admins/me/profile');
  return data;
};

/**
* * admin login (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/admins/passwordAuth/SignIn)
+*/

export const adminLogin = async (para: AdminLoginPayload) => {
  const { data } = await Axios.post<string>(
    '/admins/passwordAuth/SignIn',
    para,
  );
  return data;
};

export interface GetAdminListParams extends Filter<Admin> {
  page?: number;
  displayName?: string;
}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<Admin>>('/admins', {
    params,
  });
  return data;
};

/**
* * get one admin (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#get-/v1/admins/-adminId-)
+*/

export const getOneAdmin = async (adminId: string | number) => {
  const { data } = await Axios.get<Admin>(`/admins/${adminId}`);
  return data;
};

/**
* * create one admin (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#post-/v1/admins)
+*/

export interface CreateNewAdminParams {
  email: string;
  displayName: string;
  role: AdminType;
  loginName: string;
  password: string | null;
}

export const createOneAdmin = async (param: CreateNewAdminParams) => {
  const { data } = await Axios.post<Admin>('/admins', param);
  return data;
};

/**
* * update one admin (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#patch-/v1/admins/-adminId-)
+*/

export const updateOneAdmin = async (
  adminId: string | number,
  param: Partial<CreateNewAdminParams>,
) => {
  const { data } = await Axios.patch<null>(`/admins/${adminId}`, param);
  return data;
};

/**
* * delete one admin (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#delete-/v1/admins/-adminId-)
+*/

export const deleteAdmin = async (adminId: string | number) => {
  const { data } = await Axios.delete<string>(`/admins/${adminId}`);
  return data;
};

/**
* * reset one admin password (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#post-/v1/admins/-adminId-/resetPassword)
+*/

export const resetAdminPassword = async (
  adminId: number | string,
  para: { newPassword?: string },
) => {
  const { data } = await Axios.post<string>(
    `/admins/${adminId}/resetPassword`,
    para,
  );
  return data;
};

/**
* * admin logout (https://weaveliving-app-dev.appicidea.com/doc/mobile-app-internal/v2#post-/v1/admins/me/logout)
+*/

export const adminLogout = async () => {
  const { data } = await Axios.post<any>('/admins/me/logout');
  return data;
};
