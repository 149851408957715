import * as Sentry from '@sentry/react';
import { LANG_CONFIG } from 'app/config/languages';
import { PartnerListItem, PartnerTranslation } from 'app/models';
import { MultiLangForm } from 'app/models/lang';
import {
  PARTNER_FORM_ITEM,
  PartnerFormItem,
} from 'app/pages/PartnerPage/PartnerFormPage';
import { CreateOnePartnerParams } from 'app/services/PartnerService';
import { FormikHelpers } from 'formik';
import moment from 'moment';

import { getImageURL } from './CommonHelper';
import { uploadMultipleImages, uploadSingleImage } from './FormHelper';
import { formatTranslationData } from './MultiLangHelper';

export const formatPartnerFormData = async (
  forms: MultiLangForm<PartnerFormItem>,
  actions: FormikHelpers<MultiLangForm<PartnerFormItem>>,
) => {
  const DEFAULT_LANG = 'en';

  const {
    icon,
    images,
    name,
    partnerCategoryId,
    isPublishNow,
    publishedDate,
    contents,
    categoryName,
  } = forms[DEFAULT_LANG];

  const tempParam: CreateOnePartnerParams = {
    icon: '',
    images: [],
    name,
    partnerCategoryId: Number(partnerCategoryId),
    publishedDate:
      isPublishNow === 'true'
        ? moment().toISOString()
        : moment(publishedDate[0]).toISOString(),
    contents: contents || '',
    PartnerTranslations: [],
    categoryName,
  };

  for (const key in tempParam) {
    if (tempParam[key] === null) {
      delete tempParam[key];
    }
  }

  try {
    tempParam.images = images ? await uploadMultipleImages(images) : [];
    tempParam.icon = icon ? await uploadSingleImage(icon) : '';

    for (const lang in forms) {
      const form = forms[lang];
      if (lang !== DEFAULT_LANG) {
        const { images, icon, name, contents, partnerTranslationId } = form;
        if (name) {
          try {
            const tempForm: PartnerTranslation = {
              partnerTranslationId: partnerTranslationId
                ? partnerTranslationId
                : null,
              name,
              locale: lang,
              contents,
            };

            tempForm.icon = icon ? await uploadSingleImage(icon) : '';

            const imageRes = images ? await uploadMultipleImages(images) : '';
            if (imageRes) {
              tempForm.images = imageRes;
            }

            if (!partnerTranslationId) {
              delete tempForm.partnerTranslationId;
            }
            tempParam.PartnerTranslations &&
              tempParam.PartnerTranslations.push(tempForm);
          } catch (err) {
            console.log(err);
            Sentry.captureException(err);
            throw new Error('Failed to update images or icon.');
          }
        }
      }
    }
    return tempParam;
  } catch (err) {
    console.log(err);
    Sentry.captureException(err);
    throw new Error('Failed to upload image');
  }
};

export const transformPartnerToFormData = async (getRes: PartnerListItem) => {
  const DEFAULT_LANG = 'en';
  const {
    icon,
    images,
    name,
    partnerCategoryId,
    publishedDate,
    contents,
    PartnerTranslations,
    categoryName,
  } = getRes;

  const defaultForm = {
    name,
    partnerCategoryId: partnerCategoryId ? partnerCategoryId.toString() : '',
    isPublishNow: 'false',
    publishedDate: publishedDate ? [moment(publishedDate).toDate()] : '',
    contents,
    icon: icon
      ? [
          {
            preview: getImageURL(icon, 'original'),
            image: icon,
            name: 'Current Icon',
          },
        ]
      : null,
    images:
      Array.isArray(images) && images.length > 0
        ? images.map(img => {
            return {
              preview: getImageURL(img, 'original'),
              image: img,
              name: 'Current Image',
            };
          })
        : null,
    categoryName,
  };

  const tempForm = LANG_CONFIG.reduce(
    (prev, current) => {
      if (current === DEFAULT_LANG) {
        return { ...prev };
      } else {
        const tempForm = formatTranslationData<
          MultiLangForm<PartnerFormItem>,
          PartnerTranslation
        >(
          current,
          trans => {
            const { icon, images, ...lang } = trans;
            const temp = {
              ...lang,
            };

            if (icon) {
              temp['icon'] = [
                {
                  preview: getImageURL(icon, 'original'),
                  image: icon,
                  name: 'Current Icon',
                },
              ];
            }
            if (Array.isArray(images) && images.length > 0) {
              temp['images'] = images.map(img => {
                return {
                  preview: getImageURL(img, 'original'),
                  image: img,
                  name: 'Current Image',
                };
              });
            }
            return {
              ...prev,
              [trans.locale]: temp,
            };
          },
          PartnerTranslations,
        );
        if (tempForm) {
          return tempForm;
        } else {
          return {
            ...prev,
            [current]: {
              ...PARTNER_FORM_ITEM,
              partnerTranslationId: null,
            },
          };
        }
      }
    },
    { [DEFAULT_LANG]: defaultForm },
  );
  return tempForm;
};
