/* eslint-disable jsx-a11y/role-supports-aria-props */
// MetisMenu
import MetisMenu from 'metismenujs';
import React, { useCallback, useEffect, useRef } from 'react';
//i18n
// import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
// //Import Scrollbar
import SimpleBar from 'simplebar-react';

const SidebarContent = props => {
  // const { t } = useTranslation();
  const ref = useRef<any>();
  const location = useLocation();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem: any = null;
      const ul = document.getElementById('side-menu');
      const items = ul?.getElementsByTagName('a');
      if (items) {
        for (let i = 0; i < items.length; ++i) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname, activateParentDropdown]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        if (ref && ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%', minHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Property Management</li>
            <li>
              <NavLink
                to="/case-categories"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-information-outline"></i>
                <span>Cases</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/faq"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-comment-question-outline"></i>
                <span>FAQ</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/properties"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-home-city-outline"></i>
                <span>Property</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/generals"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-home-search-outline"></i>
                <span>General Information</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/add-on"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-puzzle-plus"></i>
                <span>Shop</span>
              </NavLink>
            </li>

            <li>
              <NavLink className="has-arrow waves-effect" to="/partners">
                <i className="mdi mdi-handshake"></i>
                <span>Partners</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/partners" activeClassName="active mm-active">
                    <span>Partners</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/partners-categories"
                    activeClassName="active mm-active"
                  >
                    Partner Category
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink className="has-arrow waves-effect" to="/events">
                <i className="mdi mdi-calendar-month-outline"></i>
                <span>Event</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/events" activeClassName="active mm-active">
                    Event
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/event-categories"
                    activeClassName="active mm-active"
                  >
                    Event category
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/event-calendar"
                    activeClassName="active mm-active"
                  >
                    Event calendar
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="menu-title">News</li>

            <li>
              <NavLink className="has-arrow waves-effect" to="/surveys">
                <i className="bx bx-news"></i>
                <span>Announcement</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/announcements"
                    activeClassName="active mm-active"
                  >
                    Announcement List
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/announcements-categories"
                    activeClassName="active mm-active"
                  >
                    Announcement Category
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* <li>
              <NavLink
                to="/banners"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-view-carousel-outline"></i>
                <span>Banners</span>
              </NavLink>
            </li> */}

            <li>
              <NavLink className="has-arrow waves-effect" to="/banners">
                <i className="mdi mdi-view-carousel-outline"></i>
                <span>Banners</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/banners" activeClassName="active mm-active">
                    Home Banner
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/community-banners"
                    activeClassName="active mm-active"
                  >
                    Community Banner
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/popup-banner"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    {/* <i className="mdi mdi-party-popper"></i> */}
                    <span>Pop up Banners</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* <li>
              <NavLink
                to="/popup-banner"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-party-popper"></i>
                <span>Pop up Banners</span>
              </NavLink>
            </li> */}

            <li className="menu-title">Users</li>

            <li>
              <NavLink
                to="/admins"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-user-circle"></i>
                <span>Admin</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/users"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-user"></i>
                <span>Member</span>
              </NavLink>
            </li>

            <li>
              <NavLink className="has-arrow waves-effect" to="/surveys">
                <i className="fab fa-wpforms"></i>
                <span>Survey</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/surveys-firsttime-login"
                    activeClassName="active mm-active"
                  >
                    First Time Login Survey
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/surveys-pre-checkin"
                    activeClassName="active mm-active"
                  >
                    Pre-check In Survey
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/surveys-satisfaction"
                    activeClassName="active mm-active"
                  >
                    Resident Satisfaction Survey
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/surveys-checkout"
                    activeClassName="active mm-active"
                  >
                    Checkout Survey
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="menu-title">Others</li>

            <li>
              <NavLink
                to="/maintenances"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-progress-wrench"></i>
                <span>Maintenance</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/notifications"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-bell"></i>
                <span>Notification</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
