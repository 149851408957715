import React from 'react';
import Modal from 'react-modal';

import './dialog.scss';

interface Props {
  visible: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'fullscreen';
  title: string;
  onClose: () => void;
  children: React.ReactChild;
  onConfirm?: () => void;
  loading?: boolean;
  btnType?:
    | 'danger'
    | 'success'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'warning'
    | 'dark'
    | 'light';
  confirmBtnText?: string;
  isStatic?: boolean;
}

Modal.setAppElement('#root');

const Dialog = ({
  visible,
  size = 'md',
  title,
  onClose,
  onConfirm,
  loading,
  children,
  btnType,
  confirmBtnText,
  isStatic = false,
}: Props) => {
  return (
    <Modal
      isOpen={visible}
      className={`dialog-modal ${size ? size : ''}`}
      overlayClassName="dialog-overlay"
      onRequestClose={isStatic ? () => null : onClose}
      closeTimeoutMS={100}
      id="select-user-modal"
    >
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button className="modal-close-btn">
          <i className="mdi mdi-close" onClick={onClose} />
        </button>
      </div>

      <div className="modal-content">{children}</div>

      {onConfirm ? (
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light me-2"
            onClick={onClose}
            disabled={loading ? true : false}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`btn btn-${btnType ? btnType : 'danger'}`}
            onClick={onConfirm}
            disabled={loading ? true : false}
          >
            {loading ? (
              <i className="bx bx-loader-circle bx-spin" />
            ) : (
              `${confirmBtnText ? confirmBtnText : 'Confirm'}`
            )}
          </button>
        </div>
      ) : null}
    </Modal>
  );
};

export default Dialog;
