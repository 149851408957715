/**
 *
 * Asynchronously loads the component for PartnerListPage
 *
 */
import { lazyLoad } from 'utils/loadable';

export const PartnerDetailPage = lazyLoad(
  () => import('./index'),
  module => module.PartnerDetailPage,
);
