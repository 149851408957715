import { Image } from 'app/components/UI';
import { getImageURL } from 'app/helpers/CommonHelper';
import { Link } from 'react-router-dom';

interface Props {
  icon: string | null;
  name: string;
  link: string;
  id?: string;
}

export const CaseCategoryBreadCrumbItem = ({ id, icon, name, link }: Props) => {
  return (
    <Link to={link} className="breadcrumb-item-container">
      <div className="text-muted mb-2">{id || '-'}</div>
      <div className="d-flex flex-row align-items-center justify-content-start">
        {icon ? (
          <div className="avatar-xs">
            <Image
              className="avatar-title bg-white"
              src={getImageURL(icon)}
              alt={'-'}
            />
          </div>
        ) : (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle bg-info">
              <i className="mdi mdi-information-outline font-size-16"></i>
            </span>
          </div>
        )}

        <div className="text-dark ms-2 name-text">{name}</div>
      </div>
    </Link>
  );
};
